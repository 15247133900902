/** Utilities for resolving paths with resolvers */
import { Typed, Types } from '@mapcast/core-type';
// NOTE: This will break if you ever add @babel/preset-env, because async function(){}
// becomes just function(){}, and will break this statement
const AsyncFunction = Object.getPrototypeOf(async function () { }).constructor;
export function args() {
    const functionArgs = {
        Types,
        Typed
    };
    return functionArgs;
}
/**
 * Creates a javascript function with all common arguments. Throws if it fails
 * to parse.
 * Returned function will throw if some other error occurs
 * @todo Add caused by clause to Exceptions
 * @todo Better heuristic for choosing AsyncFunction vs Function (should try Function
 * and if fails to parse use AsyncFunction? Or do we go the AST route, depending on
 * if the framework is already retrieving AST)
 */
export function parse(sourceStr) {
    const fArgs = args();
    // Choose Function or AsyncFunction
    // TODO: better heuristic
    const FunctionCls = sourceStr.match(/\bawait\b/) ? AsyncFunction : Function;
    const f = new FunctionCls(...Object.keys(fArgs), sourceStr);
    return () => {
        // TODO: In the future this should automatically return the last expression
        // if it doesn't return. Need to decompile to AST and insert a return statement
        // https://stackoverflow.com/questions/69939573/new-function-return-last-value
        return f(...Object.values(fArgs));
    };
}
/**
 * Runs a javascript string, throws on parse failure or execution failure
 */
export function evaluate(sourceStr) {
    return parse(sourceStr)();
}
