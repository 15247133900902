import React, { useState, useEffect, useMemo } from 'react';
import { CastToEl, useEffectBindInspectable } from '@mapcast/ui-react-core';
import { InspectablePromise } from 'inspectable-promise';
function normalizePath(path) {
    return (path.endsWith('/') || path === '') ? path.slice(0, -1) : path;
}
export default function NodeViewer(props) {
    const { className, path, resolver, ctx: _ctx, typeHint } = props;
    const hasPath = path !== null;
    const ctx = useMemo(() => ({
        ..._ctx,
        typeBrand: "@mapcast/NodeViewer/viewer",
        resolver,
        path,
    }), [_ctx, resolver, path]);
    // Listen for changes
    const [valueChangeKey, setValueChangeKey] = useState(0);
    useEffect(() => {
        if (!path) {
            return;
        }
        function onPathChange() {
            setValueChangeKey(prev => prev + 1);
        }
        const _path = path.endsWith('/') ? path.slice(0, -1) : path;
        resolver.listen(_path, onPathChange);
        return () => {
            resolver.unlisten(_path, onPathChange);
        };
    }, [path]);
    // Find the object at self
    const selfPromise = useMemo(() => {
        if (!hasPath) {
            return InspectablePromise.wrapPromise(Promise.resolve(null));
        }
        const selfResolved = resolver.get(normalizePath(path));
        return InspectablePromise.wrapPromise(Promise.resolve(selfResolved));
    }, [path, valueChangeKey]);
    useEffectBindInspectable(selfPromise);
    // useEffect(()=>{
    //   // Once the promise resolves, check the type to
    //   // * Provide quick view if it is that
    //   if (!selfPromise?.$inspect.finished) {
    //     return;
    //   }
    //   const value = selfPromise.$inspect.resolvedWith;
    //   // let typeToUse = typeHint
    //   //   ? typeHint
    //   //   : Types.typeOf(value);
    // }, [typeHint, selfPromise.$inspect.finished]);
    return React.createElement("div", { className: `${className}` }, hasPath
        && (React.createElement(React.Fragment, null,
            React.createElement(CastToEl, { data: selfPromise, ctx: ctx }))));
}
