import React, { useState } from 'react';
import CastToEl from '../CastToEl.js';
/**Unpacks a function to its last return/thrown call*/
export default function UnpackModuleMainToEl(props) {
    const { data } = props;
    const [mod] = useState(new data());
    const [modF] = useState(() => mod.main.bind(mod));
    //const main = useFunction(modF);
    // TODO: We might be able to just unpack the entirety of the module and the state
    // to CastToEl
    // TODO: There's no way to get the module out of here... We would need to add
    // a hook to get the instance out so that we can use the state
    return React.createElement(CastToEl, { data: modF });
}
