import { KNOWN_PROPS } from './IResolver.js';
/**
 * Applies all of the provided `functions` one-by-one in right-to-left order
 * starting from the `argument`.
 */
export function compose(...functions) {
    return (arg) => {
        const length = functions.length;
        let composition = arg;
        for (let index = length - 1; index >= 0; index -= 1) {
            composition = functions[index](composition);
        }
        return composition;
    };
}
/**Takes an IResolver and an array of IResolverMiddlewares and composes them into
 * one IResolver
 */
export function composeResolver(...pipeline) {
    //function composeResolver<TOut, P extends IRPipeline>(...pipeline: P): IResolver<TOut> {
    // Create one beeg IResolver
    const ret = {
        isIResolver: true
    };
    const r = pipeline[0];
    for (const f of KNOWN_PROPS) {
        if (!r[f]) {
            continue;
        }
        // Create a function that calls all functions of pipeline in sequence
        const pipelineFn = (path) => {
            let nextArg = path;
            for (const resolverOrMiddleware of pipeline) {
                if (!resolverOrMiddleware[f]) {
                    // Skip if this middleware doesnt have [f]
                    continue;
                }
                nextArg = resolverOrMiddleware[f](nextArg);
            }
            return nextArg;
        };
        ret[f] = pipelineFn;
    }
    return ret;
}
// class EchoResolver extends IResolver<string> {
//   children(path: string): MaybePromise<INodeChild[]> { throw new Error ('not implemented'); }
//   hasChildren(path: string): MaybePromise<boolean> { throw new Error ('not implemented'); }
//   typeHint(path: string): MaybePromise<Type> { throw new Error ('not implemented'); }
//   isCircular(path: string): MaybePromise<boolean> { throw new Error ('not implemented'); }
//   get(path: string): MaybePromise<T> {
//     throw new Error('not implemented');
//   }
//   options(path: string): MaybePromise<KnownOptions[]> {
//     throw new Error('not implemented');
//   }
//   add(path: string): void {
//     throw new Error('not implemented');
//   }
//   delete(path: string): void {
//     throw new Error('not implemented');
//   }
// }
// class EchoResolverMiddleware implements IResolverMiddleware<string, string> {
//   get(prev: MaybePromise<string>): MaybePromise<string> {
//     throw new Error('not implemented');
//   }
// }
// const composed = composeResolver(new EchoResolver(), new EchoResolverMiddleware());
