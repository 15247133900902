import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Types } from '@mapcast/core-type';
import Inspector from '../../elements/Inspector.jsx';
Types;
export function TextMarkdownRender(o, castCtx) {
    return React.createElement(Inspector, { castCtx: castCtx },
        React.createElement(ReactMarkdown, null, o.valueOf()));
}
;
export function configure(m) {
    m.addT(TextMarkdownRender, Types.forObject(undefined, undefined, Types.forStructured({ "__mediaType": Types.forLiteral("text/markdown") })));
}
