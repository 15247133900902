var _a;
import { createAdapter } from 'webdav-fs';
import { wfsPromisify } from '../util/webdavfsPromisify.js';
import { PropResolver, composeResolver } from '@mapcast/core-data-tree';
import { Types, Typed } from '@mapcast/core-type';
import { FSResolver, FileExtMiddleware, ImageFileMiddleware } from '@mapcast/resolver-fs';
import { resolver as auctionResolver } from '../../../app-auctions/app/config.jsx';
import { makeColorConverter } from '../sources/colorConverter/colorConverter.js';
// TODO: This import will be pruned if we dont use it!
Types;
class TestError extends Error {
}
const testWebDavFS = new FSResolver(wfsPromisify(createAdapter("http://localhost:7766/https://seafile.b4t.dev/seafdav/", {
    username: "cobertosrobertos+osp@gmail.com",
    password: "4BshUowoMBBP"
})));
const circularObj = {
    dummy: 'dummy',
    circularRef: undefined
};
circularObj.circularRef = circularObj;
export const resolver = new PropResolver({
    demo: {
        hewwo: Typed.intersectT(`
# Welcome to MapCast

Hey, you're here! Thanks for stopping by.

### What is MapCast?

MapCast is a framework for building data-driven applications.

It does this by:

*   Providing a debugging environment, built in MapCast, for exploring and interacting with data
*   Providing GUI primitives for presenting and working with common data types
*   Outputting a deployable bundle accpeted at services like [Vercel](https://vercel.com)

In the future it hopes to provide:

*   An API for connecting together multiple MapCast applications so they can share data, maybe between you and your friends or third parties.
*   Even easier deployment of MapCast applications, to something like \`myapp.sites.mapca.st\`
*   A large list of plugins and data sources, some official, some through the community.

### How do I get this? Where can mess with the code?

We'll you're currently looking at a MapCast application.

The code is currently closed source. I would like to slowly roll it out to developers in a methodical way and open source it in the future.

We have a [Discord](https://discord.gg/qMQxTEWm) if you'd like to chat, but aren't currently accepting people to develop with MapCast.

`, Types.forObject(undefined, undefined, Types.forStructured({ "__mediaType": Types.forLiteral("text/markdown") })))
    },
    _fs: testWebDavFS,
    programs: {
        ColorConverter: makeColorConverter()
    },
    tests: {
        test_circular: circularObj,
        test_module: (_a = class TestModule {
                static test() { return 'this is a test'; }
            },
            _a.asdf = 2,
            _a),
        test_number: 2,
        test_string: 'this is a string, dont at me',
        test_undefined: undefined,
        test_null: null,
        test_empty_array: [],
        test_array: ['a', 'b', 'c'],
        test_error: new TestError('Just a test error'),
        test_compose: composeResolver(testWebDavFS, new FileExtMiddleware({
            'png': new ImageFileMiddleware()
        }))
    },
    auctions: auctionResolver
});
