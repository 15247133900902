import React, { useState, useEffect } from 'react';
import { useConstructor } from '@mapcast/ui-react-core';
import styles from './SidebarAndWorkspace.module.scss';
export default function SidebarAndWorkspace(props) {
    const { id, children, initialW } = props;
    const storageKey = `sidebar-settings-${id}`;
    const [w, setW] = useState(initialW || 200);
    useConstructor(() => {
        const windowValue = window.localStorage.getItem(storageKey);
        const windowParams = windowValue ? JSON.parse(windowValue) : null;
        const initW = windowParams?.w ?? initialW ?? 300;
        setW(initW);
    });
    const [isResizing, setIsResizing] = useState(false);
    const [startMX2, setStartMX2] = useState(undefined);
    const [startW, setStartW] = useState(undefined);
    const resizeStart = (e) => {
        setStartMX2(e.clientX);
        setStartW(w);
        setIsResizing(true);
    };
    useEffect(() => {
        if (!isResizing) {
            return;
        }
        const resizeMove = (e) => {
            const xDiff = e.clientX - startMX2;
            setW(Math.max(1, startW + xDiff));
        };
        const resizeEnd = () => {
            window.localStorage.setItem(storageKey, JSON.stringify({ w }));
            setStartMX2(undefined);
            setIsResizing(false);
        };
        window.addEventListener('mousemove', resizeMove);
        window.addEventListener('mouseup', resizeEnd);
        return () => {
            window.removeEventListener('mousemove', resizeMove);
            window.removeEventListener('mouseup', resizeEnd);
        };
    }, [isResizing]);
    return React.createElement("div", { className: styles.sidebarAndWorkspace },
        React.createElement("div", { className: 'sidebar', style: { width: `${w}px` } }, children[0]),
        React.createElement("div", { onMouseDown: resizeStart, className: 'sidebar-drag-handle' }),
        React.createElement("div", { className: 'workspace' }, children[1]));
}
