import { strict as assert } from 'assert';
/**Not the most memory-efficient, but we'll worry about that in the future
 */
export function wrapFunction(func) {
    // TODO: Replace with InspectableFunction .is
    if (func.$inspect) {
        return func; // already wrapped;
    }
    function f(...args) {
        let returnedWith = null, thrownWith = null, thrown = false;
        try {
            returnedWith = func(...args);
        }
        catch (e) {
            // We should really only be throwing errors...
            assert(e instanceof Error, 'dont throw things that arent errors');
            thrown = true;
            thrownWith = e;
        }
        const call = {
            arguments: args,
            returned: !thrown,
            returnedWith,
            thrown,
            thrownWith,
        };
        f.$inspect.calls.push(call);
        if (f.$inspect.onCall) {
            f.$inspect.onCall();
        }
        if (f.$inspect.onChange) {
            f.$inspect.onChange();
        }
        if (thrownWith) {
            // Rethrow
            throw thrownWith;
        }
        return returnedWith;
    }
    f.$inspect = {
        calls: [],
        onChange: null
    };
    return f;
}
