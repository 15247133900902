import React, { useState } from 'react';
import NodeExplorerRoot from './NodeExplorerRoot.jsx';
import NodeViewer from './NodeViewer.jsx';
import SidebarAndWorkspace from '../layout-primitives/SidebarAndWorkspace.jsx';
import styles from './NodeExplorerAndViewer.module.scss';
export default function NodeExplorerAndViewer(props) {
    const { resolver, peekMap, initialPath, initialOpenPath: _initialOpenPath, initialSelectedPath } = props;
    const [explorerPath, setExplorerPath] = useState(() => initialPath ?? '/');
    const [initialOpenPath, setInitialOpenPath] = useState(() => _initialOpenPath ?? null);
    const [selectedPath, setSelectedPath] = useState(null);
    const [selectedCtx, setSelectedCtx] = useState(null);
    function onSelect(newPath, ctxAtPath) {
        setSelectedPath(newPath);
        const c = { ...ctxAtPath };
        delete c.typeBrand;
        setSelectedCtx(ctxAtPath);
    }
    return React.createElement("div", { className: styles.neav },
        React.createElement(SidebarAndWorkspace, { id: 'sidebar', initialW: 200 },
            React.createElement("div", { className: 'node-explorer-root' },
                React.createElement(NodeExplorerRoot, { peekMap: peekMap, path: explorerPath, onViewportPath: setExplorerPath, onSelect: onSelect, resolver: resolver, openPath: initialOpenPath, initialSelectedPath: initialSelectedPath })),
            React.createElement(NodeViewer, { className: 'node-viewer', path: selectedPath, ctx: selectedCtx, resolver: resolver })));
}
