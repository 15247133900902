import * as React from 'react';
import scss from './ErrorPrinter.module.scss';
export default function ErrorPrinter(props) {
    const { error, data, hideStack } = props;
    const activeError = error || data;
    const errorHeadline = activeError?.toString() || 'No error was passed to <ErrorPrinter>';
    const errorStack = activeError?.stack || '';
    return (React.createElement("div", { className: scss.errorPrinter },
        React.createElement("span", { className: "headline" }, errorHeadline),
        React.createElement("br", null),
        hideStack ? '' : errorStack));
}
