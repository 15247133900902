import React from 'react';
import { isAsyncIterable, isIterable, nameFn } from '@mapcast/core';
import { Path } from '@mapcast/core-path';
import { Types } from '@mapcast/core-type';
import { CastMap } from '@mapcast/core-cast';
import { PngImage } from '@mapcast/resolver-fs';
import ImageEl from '../elements/ImageEl.js';
import FileREPL from '../elements/FileREPL.js';
import { UnpackIterableToEls, UnpackFunctionReturnToEl, UnpackModuleMainToEl, ErrorPrinter, AwaitToEl, SafeDebugPrinter } from '@mapcast/ui-react-core';
import MultiDebugPrinter from '../elements/MultiDebugPrinter.jsx';
import Inspector from '../elements/Inspector.jsx';
import NodeExplorer from '../elements/node-explorer/NodeExplorer.jsx';
import { peekMap } from './preview-config.js';
import { configure as mdConfigure } from './features/markdown.jsx';
// TODO: This import will be pruned if we dont use it!
Types;
export const castMap = new CastMap();
const cm = castMap;
mdConfigure(cm);
// Path brand
cm.addT(nameFn("UnpackIterableToEls", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(UnpackIterableToEls, { data: o, ctx: castCtx, unpackOnMount: 100 }))), Types.forIntersection(Types.forObject(Types.forGeneric(Types.forIntrinsic("any")), Types.forInstanceof(Array), undefined), Types.forObject(undefined, undefined, Types.forStructured({ "__brand": Types.forLiteral("@mapcast/NodeExplorer/path") }))));
cm.addT(nameFn("NodeExplorer", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(NodeExplorer, { path: Path.joinStrs(castCtx.path, o.name), peekMap: peekMap, ctx: castCtx, typeHint: o.typeHint, resolver: castCtx.resolver }))), Types.forIntersection(Types.forObject(undefined, undefined, Types.forStructured({ "name": Types.forIntrinsic("string") })), Types.forObject(undefined, undefined, Types.forStructured({ "__brand": Types.forLiteral("@mapcast/NodeExplorer/path") }))));
cm.add((o) => typeof o === 'object' && o !== null && o.isAInspectableModule, nameFn("UnpackModuleMainToEl", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(UnpackModuleMainToEl, { data: o }))));
PngImage; // prevent from culling
cm.addT(function UnpackFileContainingImageToEl(o, castCtx) {
    return React.createElement(Inspector, { castCtx: castCtx },
        React.createElement(ImageEl, { data: o }));
}, Types.forObject(undefined, Types.forInstanceof(PngImage), undefined));
cm.addT(function UnpackFileContainingImageToEl(o, castCtx) {
    return React.createElement(Inspector, { castCtx: castCtx },
        React.createElement(AwaitToEl, { data: o.image() }));
}, Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("FILE"), "image": Types.forIntrinsic("any") })));
cm.addT(nameFn("FileREPL", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(FileREPL, { data: o }))), Types.forObject(undefined, Types.forInstanceof(File), undefined));
cm.addT(nameFn("FileREPL", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(FileREPL, { data: o }))), Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forIntrinsic("string") })));
cm.addT(nameFn("ErrorPrinter", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(ErrorPrinter, { error: o }))), Types.forObject(undefined, Types.forInstanceof(Error), undefined));
cm.addT(nameFn("AwaitToEl", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(AwaitToEl, { data: o, ctx: castCtx }))), Types.forObject(Types.forGeneric(Types.forIntrinsic("any")), Types.forInstanceof(Promise), undefined));
cm.addT(nameFn("UnpackFunctionReturnToEl", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(UnpackFunctionReturnToEl, { data: o, ctx: castCtx, callOnMount: false }))), Types.forObject(undefined, Types.forInstanceof(Function), undefined));
cm.addT(nameFn("EditableString", (o, castCtx) => {
    console.log(castCtx);
    const { resolver, path } = castCtx;
    return React.createElement(Inspector, { castCtx: castCtx },
        React.createElement("input", { type: "text", value: o, onChange: (e) => {
                const newValue = e.target.value;
                //console.log(newValue);
                resolver.set(path, newValue);
            } }));
}), Types.forIntersection(Types.forIntrinsic("string"), Types.forObject(undefined, undefined, Types.forStructured({ "__readOnly": Types.forLiteral(false) }))));
cm.addT(nameFn("MultiDebugPrinter", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(MultiDebugPrinter, { data: o }))), Types.forIntrinsic("string"));
cm.addT(nameFn("UnpackIterableToEls", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(UnpackIterableToEls, { data: o, ctx: castCtx }))), Types.forObject(Types.forGeneric(Types.forIntrinsic("any")), Types.forInstanceof(Array), undefined));
cm.add(isIterable, nameFn("UnpackIterableToEls", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(UnpackIterableToEls, { data: o, ctx: castCtx }))));
cm.add(isAsyncIterable, nameFn("UnpackIterableToEls", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(UnpackIterableToEls, { data: o, ctx: castCtx }))));
cm.addT(nameFn("SafeDebugPrinter", (o, castCtx) => React.createElement(Inspector, { castCtx: castCtx },
    React.createElement(SafeDebugPrinter, { data: o }))), Types.forIntrinsic("any"));
