import { strict as assert } from 'assert';
import { Types } from '@mapcast/core-type';
import { Path } from '@mapcast/core-path';
import { File, EntToFileType } from './File.js';
/**
 * Resolves full file paths mapping them onto the disk filepaths rooted at `/`.
 * If you don't want to map `/`, then use a relative fs implementation. It's
 * safer to handle folder escapes at that level in the stack
 * @todo fsImpl typing for fs interface
 */
export class FSResolver {
    /**
     * @param fsImpl The fs implementation to use for resolving files, like 'fs/promises'
     * @param fileDSCls A Dataset class that is output for every file resolved
     * by this resolver
     */
    constructor(fsImpl, fileDSCls = File) {
        this.isIResolver = true;
        assert(typeof fsImpl === 'object', `fsImpl must be of type 'object'; was '${typeof fsImpl}'`);
        this.__fsImpl = fsImpl;
        this.__fileDSCls = fileDSCls;
    }
    _getFSPath(path) {
        assert(typeof path === 'string', 'path must be a string');
        let unixPath = Path.normalize(path);
        if (!unixPath.startsWith('/')) {
            unixPath = `/${path}`;
        }
        return unixPath;
    }
    _getFSFile(path) {
        const fsPath = this._getFSPath(path);
        const Cls = this.__fileDSCls;
        const file = new Cls(fsPath, this.__fsImpl);
        return file;
    }
    async _hasCircular(fsPath) {
        const realPath = await this.__fsImpl.realpath(fsPath);
        // TODO: This is a very naive way to do this for now
        return fsPath.includes(realPath)
            && fsPath !== realPath;
    }
    async children(path) {
        // Will throw if not exists, but the caller should be able to handle this
        const fsPath = this._getFSPath(path);
        const children = await this.__fsImpl.readdir(fsPath, { withFileTypes: true });
        return children
            .map((c) => ({
            name: c.name,
            typeHint: Types.forIntersection(
            // Can't unit test with tstype-transformer
            //Types.forTS<File>(),
            Types.forInstanceof(File), Types.forStructured({ __fileType: Types.forLiteral(EntToFileType(c)) }))
        }));
    }
    async hasChildren(path) {
        return (await this.children(path)).length > 0;
    }
    async typeHint(path) {
        const fsPath = this._getFSPath(path);
        const stat = await this.__fsImpl.stat(fsPath);
        return Types.forIntersection(
        // Can't unit test with tstype-transformer
        //Types.forTS<File>(),
        Types.forInstanceof(File), Types.forStructured({ __fileType: Types.forLiteral(EntToFileType(stat)) }));
    }
    async isCircular(path) {
        const fsPath = this._getFSPath(path);
        return this._hasCircular(fsPath);
    }
    // == verbs ==
    options(path) {
        return ['get', 'add', 'delete'];
    }
    async get(path) {
        const fsPath = this._getFSPath(path);
        const fsFile = this._getFSFile(fsPath);
        // TODO: We should really be doing this a different way... Maybe storing the
        // children() returns and baking them into fsFile instead of calling a .stat()
        // for every file in get(). That plus I dont like setting this explicitly, it'd
        // be better encapsulated in the class
        try {
            // Bake in __fileType into the fsFile externally to that typing based on
            // .type() can happen synchronously
            fsFile.__fileType = await fsFile.type();
        }
        catch (e) { }
        return fsFile;
    }
    async set(path, value) {
        throw new Error('TODO: not implemented');
        return;
    }
    async add(path) {
        // TODO: This needs to write the file at path, not a random name
        const fsPath = this._getFSPath(path);
        const newName = `New File ${Date.now()}`;
        const newPath = Path.joinStrs(path, newName);
        await this.__fsImpl.writeFile(newPath, '');
    }
    /**Deletes the node at a given path
     * @todo Should we only delete nodes that don't connect to anything (non-folders?).
     * Think of atomicity here... Less important for adding
     */
    async delete(path) {
        const fsPath = this._getFSPath(path);
        const stat = await this.__fsImpl.stat(fsPath);
        if (stat.isDirectory()) {
            // TODO: Do we do some sort of rimraf thing?
            // XXX: Will fail if it has stuff in it
            await this.__fsImpl.rmdir(path);
        }
        else {
            await this.__fsImpl.unlink(path);
        }
    }
    listen(path, cb) {
        console.warn('TODO: noop for FSResolver');
    }
    unlisten(path, cb) {
        console.warn('TODO: noop for FSResolver');
    }
}
