import React, { useMemo } from 'react';
import * as ReactDOM from "react-dom";
import styles from './FloatingContainer.module.scss';
function isMouseEvent(at) {
    return !!at && typeof at.clientY === 'number';
}
export default function FloatingContainer(props) {
    const { at, children, style, className } = props;
    const { top, left } = useMemo(() => {
        let pos;
        if (at instanceof HTMLElement) {
            const rect = at.getBoundingClientRect();
            const scrollTop = window.scrollY;
            const scrollLeft = window.scrollX;
            pos = {
                top: rect.y + scrollTop,
                left: rect.x + scrollLeft
            };
        }
        else if (isMouseEvent(at)) {
            pos = {
                top: at.clientY,
                left: at.clientX
            };
        }
        else {
            pos = at;
        }
        return pos;
    }, [at]);
    return ReactDOM.createPortal((React.createElement("div", { className: `${className} ${styles.floatingContainer}`, style: { position: 'absolute', top, left, ...style } }, children)), document.body);
}
