import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Path } from '@mapcast/core-path';
import NodeExplorerPathInput from './NodeExplorerPathInput.jsx';
import NodeExplorer from './NodeExplorer.jsx';
import ContextMenu from './ContextMenu.jsx';
export default function NodeExplorerRoot(props) {
    const { className, path, openPath, initialSelectedPath, onViewportPath, onSelect, resolver, ctx: _ctx, peekMap } = props;
    const rootRef = useRef();
    // Normalize the path to not have a '/'
    function setNewPath(newPath) {
        if (typeof onViewportPath === 'function') {
            onViewportPath(newPath);
        }
    }
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPath, setContextMenuPath] = useState('');
    const [contextMenuAt, setContextMenuAt] = useState({ top: 0, left: 0 });
    function pushContextMenu(pathAtClick, e) {
        if (!rootRef.current) {
            return;
        }
        setShowContextMenu(true);
        setContextMenuAt(e);
        setContextMenuPath(pathAtClick);
    }
    const [optionsAtContextMenuPath, setOptionsAtContextMenuPath] = useState([]);
    useEffect(() => {
        (async () => {
            const options = await resolver.options(contextMenuPath);
            setOptionsAtContextMenuPath(options);
        })();
    }, [resolver, contextMenuPath]);
    const parentPath = Path.parent(path) || undefined;
    const [selectedPath, setSelectedPath] = useState(null);
    function select(pathAtClick, e) {
        // TODO: Typing, this is the modified ctx at the node that was selected which
        // allows us to get things like typeMeta at the specific node
        const ctx = this;
        setSelectedPath(pathAtClick);
        if (typeof onSelect === 'function') {
            onSelect(pathAtClick, ctx);
        }
    }
    const ctx = useMemo(() => ({
        ..._ctx,
        resolver,
        path,
        setPath: setNewPath,
        pushContextMenu,
        select,
        selectedPath,
        openPath,
        initialSelectedPath
    }), [_ctx, resolver, path, selectedPath]);
    return React.createElement("div", { className: className, ref: rootRef },
        showContextMenu &&
            React.createElement(ContextMenu, { at: contextMenuAt, onClose: () => setShowContextMenu(false) },
                optionsAtContextMenuPath.includes('add')
                    && React.createElement("button", { onClick: () => resolver.add(contextMenuPath) }, "Add Node"),
                optionsAtContextMenuPath.includes('delete')
                    && React.createElement("button", { onClick: () => resolver.delete(contextMenuPath) }, "Delete Node"),
                React.createElement("button", { onClick: () => setNewPath(contextMenuPath) }, "Set as path")),
        React.createElement("button", { disabled: !parentPath, onClick: () => setNewPath(parentPath) }, "Up"),
        React.createElement(NodeExplorerPathInput, { path: path, onPath: setNewPath }),
        React.createElement("br", null),
        React.createElement(NodeExplorer, { path: path, resolver: resolver, ctx: ctx, peekMap: peekMap }));
}
