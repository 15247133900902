export {};
// export class RelativeResolver<T> extends IResolver<T> {
//   __redirectTo: IResolver<T>;
//   __pathFunc: (path: string)=>string;
//   constructor(redirectTo: IResolver<T>, pathFunc=(p: string)=>p) {
//     super();
//     this.__redirectTo = redirectTo;
//     this.__pathFunc = pathFunc;
//   }
//   _getPath(path: string) {
//     assert(typeof path === 'string', 'path must be a string');
//     const newPath = this.__pathFunc(path);
//     return newPath;
//   }
//   children(path: string) {
//     return this.__redirectTo.children(this._getPath(path));
//   }
//   resolve(path: string) {
//     return this.__redirectTo.resolve(this._getPath(path));
//   }
//   whichResolver(path: string) {
//     return this.__redirectTo.whichResolver(this._getPath(path));
//   }
// }
