import React, { useMemo } from 'react';
import { useInspectablePromise } from '@mapcast/ui-react-core';
import styles from './ImageEl.module.scss';
export default function ImageEl(props) {
    const { data } = props;
    const widthPromise = useMemo(() => data.width.call(data), [data]);
    const width = useInspectablePromise(widthPromise);
    const heightPromise = useMemo(() => data.height.call(data), [data]);
    const height = useInspectablePromise(heightPromise);
    const bufferPromise = useMemo(() => data.rawBuffer.call(data), [data]);
    const buffer = useInspectablePromise(bufferPromise);
    const imgSrc = useMemo(() => {
        if (!buffer.$inspect.resolved) {
            return;
        }
        const blob = new Blob([buffer.$inspect.resolvedWith]);
        return URL.createObjectURL(blob);
    }, [buffer.$inspect.resolvedWith, data]);
    return (React.createElement("div", { className: styles.imageEl },
        React.createElement("div", { className: 'image-label' },
            width.$inspect.syncValueT('?'),
            " x ",
            height.$inspect.syncValueT('?')),
        React.createElement("img", { style: { maxWidth: '200px' }, src: imgSrc })));
}
