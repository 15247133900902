import { strict as assert } from 'assert';
import memoize from 'lodash/memoize.js';
/**
 * This typing system can consume most of TypeScripts typing but has some known limitations:
 * * See limitations of tstype-transformer.ts
 * * Intrinsic 'unknown' isn't really supported (even though it can be represented)
 * * An IntersectionType with 1 type and other weird edge cases that should be simplified instead
 * of used as-is will probably have weird issues
 * * Certain things will probably not perform the best, like .structuredType of InstanceofType
 * * Functions are currently not supported (the callable, constructable, signature part,.they can
 * still be typed like objects)
 */
/** Object.keys but for Symbols and non-enumerables too */
function Object_keysAll(o) {
    return Object.getOwnPropertyNames(o)
        .concat(Object.getOwnPropertySymbols(o));
}
const TypedSymbol = Symbol.for('@mapcast/Typed/type');
/** Class that can return a runtime type */
export class Typed {
    /** TypeScript guard to check if something is ITyped */
    static is(o) {
        return !!o[TypedSymbol];
    }
    /** Get the type from an ITyped */
    static get(o) {
        assert(typeof o[TypedSymbol] === 'function', `o[TypedSymbol] must be a function, got ${typeof o[TypedSymbol]}`);
        const t = o[TypedSymbol]();
        // Do a vague test for Type inheritance
        assert(typeof t === 'object' && t.name, 'returned type of [TypedSymbol]() should be a Type');
        return t;
    }
    /**Box o into something that supports setting properties*/
    static _box(o) {
        // Box o in corresponding object if needed
        switch (typeof o) {
            case 'string':
                return new String(o);
            case 'number':
                return new Number(o);
            case 'boolean':
                return new Boolean(o);
            case 'bigint':
                return BigInt(o);
            case 'object':
            case 'function':
                return o;
            // Can't add properties to these types without compromising the functionality
            case 'symbol':
            case 'undefined':
                throw new Error('Cant type a type of type');
        }
    }
    /** Type an object, boxes primitives into their corresponding type */
    static type(o, typeFn) {
        o = this._box(o);
        o[TypedSymbol] = typeFn;
        return o;
    }
    /**Adds the Type of T to the [Typed.typeSymbol] property of the passed object
     * Requires the `tstype-transformer` to transform the TypeScript code to work */
    static typeT(o, tType) {
        assert(tType, 'tstype-transformer is required to transform calls to Typed.typeT<T> to add the type object at compile time. We received undefined. Did you add the transformer?');
        return this.type(o, () => tType);
    }
    static intersectT(o, tType) {
        assert(tType, 'tstype-transformer is required to transform calls to Typed.intersectT<T> to add the type object at compile time. We received undefined. Did you add the transformer?');
        // TODO: Would be nice to do this on the fly instead of only at the time this function is called, but that
        // might get expensive, and we cant cache it because we dont know when it will change
        // so there's a lot of weird questions there...
        o = this._box(o); // Pre-box it so Types.typeOf is correct
        const dt = Types.typeOf(o);
        return this.type(o, () => new IntersectionType(dt, tType));
    }
}
Typed.typeSymbol = TypedSymbol;
export const TypeFlags = {
    // One type per intrinsic, at least ones that make sense to combine. This allows
    // easy comparison as well as calculation across unions and intersections
    Any: 0x1,
    String: 0x2,
    Number: 0x4,
    Boolean: 0x8,
    Object: 0x10,
    BigInt: 0x20,
    Undefined: 0x40,
    Symbol: 0x80,
    Null: 0x100,
    Void: 0x200,
    Never: 0x400,
    // A -Like type for most of the above to be able to test for something that is
    // of an intrinsic type
    VoidLike: 0x1000,
    StringLike: 0x2000,
    NumberLike: 0x4000,
    BooleanLike: 0x8000,
    BigIntLike: 0x10000,
    SymbolLike: 0x20000,
    ObjectLike: 0x40000,
    MAX: 0x7FFFF,
};
/** TypeScript guard to check if something is a Type */
export function Type_is(o) {
    return typeof o === 'object' && o !== null
        && typeof o.flags === 'number'
        && typeof o.name === 'string'
        && typeof o.canContain === 'function';
}
/**A Type that has represents another type + a differentiable name. The type itself
 * matches the target type plus requiring matching of the more specific name
 * Ex:
 * AliasedType('path', new IntrinsicType('string')):
 * * Can be contained in a 'string' type
 * * Cannot contain a plain 'string' type
 * * Can contain a AliasedType('path', new InstrincType('string'))
 * * Cannot contain an AliasedType('something_else', new InstrinsicType('string))
 * @todo For now, use an intersection of the type you want + a literal
 */
// export class AliasedType implements Type {
//   _typeTarget: Type;
//   _aliasName: string;
//   constructor(name: string, typeTarget: Type) {
//     this._typeTarget = typeTarget;
//     this._aliasName = name;
//   }
//   get flags() {
//     return this._typeTarget.flags;
//   }
//   get lhsFlags() {
//     return this._typeTarget.lhsFlags;
//   }
//   get name() {
//     return this._typeTarget.name;
//   }
//   canContain(t1: Type): boolean | undefined {
//     // Only aliased types can be contained by us
//     if (!(t1 instanceof AliasedType)) {
//       return false;
//     }
//     return t1._aliasName === this._aliasName
//       && this._typeTarget.canContain(t1);
//   }
//   equals(t: Type): boolean {
//     if (!(t instanceof AliasedType)) {
//       return false;
//     }
//     return t._aliasName === this._aliasName &&
//       this._typeTarget.equals(t);
//   }
// }
/**Does an flag test on types to determine if t0 can contain t1 using intrinsic flags
 * and other flags. Should work on any type because all Types have these common flags.
 * An undefined return means we cannot determine from this information alone */
export function Type_canContainFlags(t0, t1) {
    const t0Flags = t0.lhsFlags ?? t0.flags;
    const t1Flags = t1.flags;
    if (t0Flags & TypeFlags.Any) {
        return true;
    }
    if (t0Flags & TypeFlags.Never) {
        return false;
    }
    // If t0Flags is multiple intrinsics, we can't make a false determination unless
    // t0 is comprised of only intrinsic types, but that's not something we know here.
    // We can only make true statements therefore...
    // TODO: This could be replaced with a bit shift in the future probabl
    if (t0Flags & TypeFlags.String
        && t1Flags & TypeFlags.StringLike) {
        return true;
    }
    if (t0Flags & TypeFlags.Number
        && t1Flags & TypeFlags.NumberLike) {
        return true;
    }
    if (t0Flags & TypeFlags.Boolean
        && t1Flags & TypeFlags.BooleanLike) {
        return true;
    }
    if (t0Flags & TypeFlags.BigInt
        && t1Flags & TypeFlags.BigIntLike) {
        return true;
    }
    if (t0Flags & TypeFlags.Symbol
        && t1Flags & TypeFlags.SymbolLike) {
        return true;
    }
    if (t0Flags & TypeFlags.Object
        && t1Flags & TypeFlags.ObjectLike) {
        return true;
    }
    if (t0Flags & TypeFlags.Void
        && t1Flags & TypeFlags.VoidLike) {
        return true;
    }
    if (t0Flags & TypeFlags.Undefined
        && t1Flags & TypeFlags.Undefined) {
        return true;
    }
    if (t0Flags & TypeFlags.Null
        && t1Flags & TypeFlags.Null) {
        return true;
    }
    return undefined; // No known whether it can or not
}
/**Like Type_canContainFlags but will be false when t0 is an intrinsic. Only useful
 * when not mixing intrinsics (like for IntrinsicType specifically)
 */
export function Type_canContainFlagsStrict(t0, t1) {
    const t0Flags = t0.lhsFlags ?? t0.flags;
    const t1Flags = t1.flags;
    if (t0Flags & TypeFlags.Any) {
        return true;
    }
    if (t0Flags & TypeFlags.Never) {
        return false;
    }
    // t0Flags can't be both String | Number when using this function, as we will
    // return incorrect values if t1 is the latter value
    // assert(t0Flags &
    //   (TypeFlags.String | TypeFlags.Number | TypeFlags.Boolean | TypeFlags.BigInt
    //  | TypeFlags.Symbol | TypeFlags.Object | TypeFlags.Void));
    // TODO: This could be replaced with a bit shift in the future probabl
    if (t0Flags & TypeFlags.String) {
        return !!(t1Flags & TypeFlags.StringLike);
    }
    if (t0Flags & TypeFlags.Number) {
        return !!(t1Flags & TypeFlags.NumberLike);
    }
    if (t0Flags & TypeFlags.Boolean) {
        return !!(t1Flags & TypeFlags.BooleanLike);
    }
    if (t0Flags & TypeFlags.BigInt) {
        return !!(t1Flags & TypeFlags.BigIntLike);
    }
    if (t0Flags & TypeFlags.Symbol) {
        return !!(t1Flags & TypeFlags.SymbolLike);
    }
    if (t0Flags & TypeFlags.Object) {
        return !!(t1Flags & TypeFlags.ObjectLike);
    }
    if (t0Flags & TypeFlags.Void) {
        return !!(t1Flags & TypeFlags.VoidLike);
    }
    if (t0Flags & TypeFlags.Undefined) {
        return !!(t1Flags & TypeFlags.Undefined);
    }
    if (t0Flags & TypeFlags.Null) {
        return !!(t1Flags & TypeFlags.Null);
    }
    return undefined; // No known whether it can or not
}
/**Common test to see if t0 canContain t1. t1 is guarenteed to not be a SetType
 * after calling this
 */
export function Type_canContainCommon(t0, t1) {
    // First try a flags check, because that's much faster (and works with
    // set types, as they will boolean logic the flags together)
    const ccFlags = Type_canContainFlags(t0, t1);
    if (ccFlags !== undefined) {
        return ccFlags;
    }
    // If the type to compare against (t1) is a set type, unwrap it's types and
    // do the boolean combination (some | every) so that t0 can compare
    // just non-set types
    if (isSetType(t1)) {
        return t1._canContainRHS(t0);
    }
}
/** Type that accepts name and optional flags directly, rarely used */
export class RawType {
    constructor(name, flags = 0) {
        assert(typeof name === 'string', `name should be a string, got '${typeof name}'`);
        this.name = name;
        this.flags = flags;
    }
    canContain(t1) {
        // Raw type only has/needs .flags to compare with
        return Type_canContainFlags(this, t1);
    }
    equals(t) {
        return (t instanceof RawType)
            && this.flags === t.flags
            && this.name === t.name;
    }
}
const IntrinsicTypeValues = [
    "string",
    "number",
    "boolean",
    "object",
    "bigint",
    "undefined",
    "null",
    "symbol",
    "any",
    "unknown",
    "void",
    "never"
];
/**Type for an intrinsic (a type for a concept not declared in the code but inherent
 * to the language, like Number). Name must be in IntrinsicTypeValues */
export class IntrinsicType {
    constructor(name) {
        assert(typeof name === 'string', `name should be a string, got '${typeof name}'`);
        assert(IntrinsicTypeValues.includes(name), `name should be an IntrinsicTypeValue, got '${name}'`);
        this.name = name;
        this.flags = IntrinsicType.flagsForIntrinsic[name];
    }
    canContain(t1) {
        // Intrinsic type only has/needs .flags to compare with, but it can do it more
        // strictly than Type_canContainFlags. We will never mix flags from intrinsics
        return Type_canContainFlagsStrict(this, t1);
    }
    equals(t) {
        return (t instanceof IntrinsicType)
            && this.flags === t.flags
            && this.name === t.name;
    }
}
IntrinsicType.flagsForIntrinsic = {
    "string": TypeFlags.String | TypeFlags.StringLike,
    "number": TypeFlags.Number | TypeFlags.NumberLike,
    "boolean": TypeFlags.Boolean | TypeFlags.BooleanLike,
    "bigint": TypeFlags.BigInt | TypeFlags.BigIntLike,
    "symbol": TypeFlags.Symbol | TypeFlags.SymbolLike,
    "object": TypeFlags.Object | TypeFlags.ObjectLike,
    "undefined": TypeFlags.Undefined | TypeFlags.VoidLike,
    "null": TypeFlags.Null | TypeFlags.VoidLike,
    "any": TypeFlags.Any,
    "void": TypeFlags.Void | TypeFlags.VoidLike,
    // Never can't contain anything, even itself. It can be however contained in void
    // TODO: Is this how TypeScript behaves?
    "never": TypeFlags.VoidLike,
    // TODO: These ones were not well tested against TypeScript as they're kinda
    // wonky to have at Runtime... Might remove these...
    "unknown": 0,
};
const LiteralTypeOfValues = [
    "string",
    "number",
    "boolean",
    "bigint",
    "symbol",
];
/**Type for a literal
 * @todo This should have special considerations for long/big literals
 * @todo How to store object literals?
 */
export class LiteralType {
    constructor(literal) {
        this.flags = 0;
        // TODO: We should have a type guard for the below, it would be cleaner
        this.typeOf = LiteralType.typeOf(literal); // Run first, has assertion that might throw
        // TODO: Make sure to handle uniqueness of name
        this.name = this.typeOf === 'symbol'
            ? `Symbol("${literal.description}")`
            : this.typeOf === 'string'
                ? `"${'' + literal}"` // TODO: Handle when literal is very long string
                : '' + literal;
        this.flags = LiteralType.flagsForLiteralTypeOf[this.typeOf];
        // TODO: We dont want to keep around reference to large literals, eventually this
        // should be removed and replaced with something that stores a small way to
        // identify literals (as-is up to a certain length then uses hashing or some 
        // identifier)
        this.__value = literal;
    }
    /** typeOf but against the LiteralTypeOfValues */
    static typeOf(literal) {
        const limitedTypeOf = (typeof literal);
        assert(LiteralTypeOfValues.includes(limitedTypeOf), `typeof literal should be an IntrinsicTypeValue, got '${typeof literal}'`);
        return limitedTypeOf;
    }
    get intrinsicType() {
        return Types.forIntrinsic(this.typeOf);
    }
    canContain(t1) {
        // Dont need to check flags because we will never contain anything other than ourselves
        // and possibly UnionTypes
        // Unwrap set types into their individual types
        if (isSetType(t1)) {
            return t1._canContainRHS(this);
        }
        // We can only contain other literal types that are ourselves
        if (!(t1 instanceof LiteralType)) {
            return false;
        }
        // A literal type only represents it's value, so the other type must be a literal
        // that is exactly us (after handling Unions that is, which might _contain_ us)
        return this.__value === t1.__value;
    }
    equals(t) {
        return (t instanceof LiteralType)
            && this.__value === t.__value;
    }
}
LiteralType.flagsForLiteralTypeOf = {
    "string": TypeFlags.StringLike,
    "number": TypeFlags.NumberLike,
    "boolean": TypeFlags.BooleanLike,
    "bigint": TypeFlags.BigIntLike,
    "symbol": TypeFlags.SymbolLike, // symbols are not objects-like
};
function isSetType(t) {
    return t instanceof UnionType || t instanceof IntersectionType;
}
/**Type for a union of multiple types*/
export class UnionType {
    constructor(...types) {
        this.types = types;
        this.name = types.map(t => t.name).join(' | ');
        // .flags represents what we _are_, a stringlike | numberlike can be treated as
        // neither
        this.flags = types.map(t => t.flags).reduce((acc, flag) => acc & flag, TypeFlags.MAX);
        // While .flags represents what we _are_, a number | string is able to contain both
        // types, so we have another set of flags
        this.lhsFlags = types.map(t => t.flags).reduce((acc, flag) => acc | flag, 0);
    }
    /**Used by other canContain() functions to see if they can contain our type */
    _canContainRHS(t0) {
        // For t0 to contain us, it must be able to contain ALL our types, because
        // a type of string | number is a more general/abstract type that could potentially
        // be only one or the other. A union of types has the intersection of their features.
        return this.types.every((tThis) => t0.canContain(tThis));
    }
    canContain(t1) {
        // First try a flags check, because that's much faster and works with set types
        // without an iteration
        const ccFlags = Type_canContainFlags(this, t1);
        if (ccFlags !== undefined) {
            return ccFlags;
        }
        // Otherwise, do an iteration over all the types
        return this.types.some((tt0) => tt0.canContain(t1));
        // TODO: some() doesn't take into account if the children canContain() will return undefined
    }
    equals(t) {
        return (t instanceof UnionType)
            && this.types.length === t.types.length
            && this.types.every((tThis, idx) => tThis.equals(t.types[idx]));
    }
}
/**Type for a intersection of multiple types*/
export class IntersectionType {
    constructor(...types) {
        this.types = types;
        this.name = types.map(t => t.name).join(' & ');
        // .flags represents what we _are_, a { a: number } & { b: number } can be treated
        // as both separately if needed.
        this.flags = types.map(t => t.flags).reduce((acc, flag) => acc | flag, 0);
        // While .flags represents what we _are_, a { a: number } & { b: number } is able to contain
        // only more specific types, and would need to match both types
        this.lhsFlags = types.map(t => t.flags).reduce((acc, flag) => acc & flag, TypeFlags.MAX);
    }
    /**Used by other canContain() functions to see if they can contain our type */
    _canContainRHS(t0) {
        // For t0 to contain us, it must be able to contain AT LEAST ONE of our types
        // because we are a more specific type that is both of our .types
        return this.types.some((tThis) => t0.canContain(tThis));
    }
    canContain(t1) {
        // First try a flags check, because that's much faster and works with set types
        // without an iteration
        const ccFlags = Type_canContainFlags(this, t1);
        if (ccFlags !== undefined) {
            return ccFlags;
        }
        // Otherwise, do an iteration over all the types
        return this.types.every((tt0) => tt0.canContain(t1));
        // TODO: every() doesn't take into account if the children canContain() will return undefined
    }
    equals(t) {
        return (t instanceof IntersectionType)
            && this.types.length === t.types.length
            && this.types.every((tThis, idx) => tThis.equals(t.types[idx]));
    }
}
export class StructuredType {
    constructor(props) {
        assert(Object_keysAll(props).every(k => Type_is(props[k])), 'All keys on props needs to be a Type');
        this.flags = TypeFlags.ObjectLike;
        this.props = props;
        this.name = Object_keysAll(this.props)
            .map((k) => {
            const namePart = typeof k === 'symbol'
                ? `[Symbol(${k.description})]`
                : `"${k}"`;
            return `${namePart}: ${this.props[k].name}`;
        })
            .join(', ');
        this.name = `{ ${this.name} }`;
    }
    canContain(t1) {
        // Check common first
        const canContainCommon = Type_canContainCommon(this, t1);
        if (canContainCommon !== undefined) {
            return canContainCommon;
        }
        // A StructuredType can only contain other StructuredTypes (after handling unions and such)
        if (!(t1 instanceof StructuredType)) {
            return false;
        }
        // Structured types represent an intersection between all props in .props and
        // which can only contain a type with all those props
        // Check all properties in t0 exist on and canContain t1's versions of them
        return Object_keysAll(this.props)
            .every((prop) => t1.props[prop] && this.props[prop].canContain(t1.props[prop]));
    }
    equals(t) {
        if (!(t instanceof StructuredType)) {
            return false;
        }
        const thisKeys = Object_keysAll(this.props);
        const tKeys = Object_keysAll(t.props);
        return thisKeys.length === tKeys.length
            && thisKeys.every((thisKey, idx) => thisKey === tKeys[idx])
            && thisKeys.every(thisKey => this.props[thisKey].equals(t.props[thisKey]));
    }
}
/**Represents something that is invokable, either with () or new ()*/
export class InvokableSignatureType {
}
const stubSymbol = Symbol('RTClassType Stub');
/**Type that represents something that is an instanceof some prototype. Works both with
 * class constructors (has .prototype) or a prototype with no .constructor that implements
 * hasInstance and toStringTag*/
export class InstanceofType {
    constructor(cls) {
        assert(cls.prototype, 'cls passed needs to have a .prototype to compared to with instanceof');
        assert(cls[Symbol.toStringTag] || cls.name, 'cls needs to have a way to get its name');
        this.__cls = cls;
        this.flags = TypeFlags.ObjectLike;
        this.name = (this.__cls[Symbol.toStringTag] ?? this.__cls.name);
    }
    /** Get this type but as a structured type that represents the class prototype */
    get structuredType() {
        // Recursively call getOwnPropertyDescriptors to get all the Type's of every
        // property on the prototype chain
        // TODO: This will fail with circular references in the prototype chain, but I'm
        // not sure if that's even allowed. Probably not...
        function getAllPropertiesOfPrototypeChain(proto) {
            if (!proto) {
                return {}; // No properties
            }
            // Get the properties mapped to types from just this prototype object
            // TODO: This is annoying. We could have weird side effects by accessing
            // random properties, plus, certain properties (like arguments, callee, and
            // caller on Function) will throw
            const ownPropTypeMap = Object_keysAll(proto)
                .map((pKey) => {
                // Certain properties (like .arguemtns, .callee, and .caller on Function)
                // will throw when accessed
                // TODO: This is not a safe way to continue in the future. All these
                // property accesses mixed with getters has some real issues. We should
                // either provide the user with a helper to structurally type their
                // stuff, or somehow give them the option to access getters or not while
                // typing
                let pValue;
                try {
                    pValue = proto[pKey];
                }
                catch (e) {
                    return {};
                }
                return {
                    [pKey]: pValue
                };
            })
                .reduce((acc, propObj) => ({ ...acc, ...propObj }), {});
            // Merge the parent properties with our own
            return {
                ...getAllPropertiesOfPrototypeChain(Object.getPrototypeOf(proto)),
                ...ownPropTypeMap,
            };
        }
        // Create a structured type from our data, build the properties from the
        // .prototype chain
        return Types.forStructured(
        //`StructuredFor${this.__cls.name}`,
        getAllPropertiesOfPrototypeChain(this.__cls.prototype));
    }
    canContain(t1) {
        // Check common first
        const canContainCommon = Type_canContainCommon(this, t1);
        if (canContainCommon !== undefined) {
            return canContainCommon;
        }
        // A InstanceofType can only contain other InstanceofType (after handling unions and such)
        if (!(t1 instanceof InstanceofType)) {
            return false;
        }
        // Try equality first (checks __cls and prototype for equality that instanceof
        // wont check)
        if (this.equals(t1)) {
            return true; // Equal classes can contain each other
        }
        // No equality, so now we need to see if this.__cls is a super class of t1.__cls
        // We can use instance of the .prototype of t1 to do this
        // This will work with classes that implement [Symbol.hasInstance] too
        return t1.__cls.prototype instanceof this.__cls;
        // TODO: Remove this, this is old
        // Check the prototype chain manually, GeneratorFunction fails instanceof for
        // its children function *generator(){}'s because of a [Symbol.hasInstance] in
        // the prototype chain that I couldn't find mentioned in the ECMA spec. I think
        // the issue is it using a [Symbol.hasInstance] of a parent type that it shouldnt
        // be?
        // assert(this.__cls.prototype, 'LHS of canContain() must have a .prototype if instanceof check failed');
        // return this.__cls.prototype === t1.__cls.prototype
        //   || this.__cls.prototype.isPrototypeOf(t1.__cls);
    }
    equals(t) {
        if (!(t instanceof InstanceofType)) {
            return false;
        }
        return this.__cls === t.__cls
            // If one of them is a stub (because we couldn't get the constructor and
            // _only_ have the prototype) then do a prototype equality check too
            // (neglible difference)
            || (this.__cls[stubSymbol] || t.__cls[stubSymbol]
                && this.__cls.prototype === t.__cls.prototype);
    }
}
InstanceofType.__stubSymbol = stubSymbol;
/**Type that can be conceptually thought of as containing or relating to another
 * type in an interchangeable way. Length of generic args is important, if you want
 * one generic to contain another, you need do something like <any, any, any> to
 * contain another generic with 3 parameters*/
export class GenericType {
    constructor(...genericArgs) {
        this.__tArgs = genericArgs;
        this.flags = TypeFlags.ObjectLike;
        this.name = `<${this.__tArgs.map(t => t.name).join(',')}>`;
    }
    /**Can t0's __tArgs contain t1's __tArgs
     * Only call this when t0 and t1 are the same __cls or should have the same
     * __tArgs shape, otherwise you'll most likely get an exception */
    canContain(t1) {
        if (this.__tArgs.every(t => t.equals(new IntrinsicType("any")))) {
            // Ignore generic part when all type args are any
            return true;
        }
        if (!t1) {
            // TODO: Remove this when we remove | undefined due to hack described in
            // ObjectType
            return false;
        }
        // Check common first
        const canContainCommon = Type_canContainCommon(this, t1);
        if (canContainCommon !== undefined) {
            return canContainCommon;
        }
        /*if (t1 instanceof ObjectType && t1.genericType === undefined) {
          // TODO: this allows us to be lenient with ObjectTypes that don't have a
          // genericType, which happens quite a lot when we dont know the inner type
          // or cant get it or dont have a custom typing setup for it but TS has/requires
          // a type
          return true;
        }*/
        // A GenericType can only contain other GenericType (after handling unions and such)
        if (!(t1 instanceof GenericType)) {
            return false;
        }
        if (this.__tArgs.length > t1.__tArgs.length) {
            // TODO: This works for simple cases, but what happens when you
            // class Something<T> {}
            // class NewSomething<S> extends Something<number> {}
            // There's no good way to test NewSomething against Something with the
            // current way generics are implemented... It would need to go through
            // it's inheritance heirarchy to figure out how it inherits and can't
            // positionally compare these unless they are the same ClassType!
            // We must have a smaller or equal amount of generics
            return false;
        }
        // Compare each sequential __tArgs in ct0 and ct1 against each other
        return this.__tArgs
            .every((t0Arg, idx) => t0Arg.canContain(t1.__tArgs[idx]));
    }
    equals(t) {
        if (!(t instanceof GenericType)) {
            return false;
        }
        return this.__tArgs.length === t.__tArgs.length
            && this.__tArgs.every((t0Arg, idx) => t0Arg.equals(t.__tArgs[idx]));
    }
}
/**Intersection type of ObjectTraits*/
export class ObjectType extends IntersectionType {
    constructor(genericType, instanceofType, propsType) {
        const ts = []
            .concat(propsType ? [propsType] : [])
            .concat(instanceofType ? [instanceofType] : [])
            .concat(genericType ? [genericType] : []);
        assert(ts.length >= 1, 'ObjectType must be passed at least one trait type');
        super(...ts);
        this.propsType = propsType;
        this.instanceofType = instanceofType;
        this.genericType = genericType;
        const genericName = this.genericType?.name ?? '';
        const instanceofName = this.instanceofType?.name ?? '';
        const propsName = this.propsType?.name ?? '';
        this.name = `${instanceofName}${genericName}${propsName}`;
    }
    canContain(t1) {
        // All children will check common for us, so we dont need to
        if (!(t1 instanceof ObjectType)) {
            // Use the IntersectionType canContain if the other is not an ObjectType, which will
            // check against each intersected piece individually
            return super.canContain(t1);
        }
        // Compare ObjectTypes against each other in a less pairwise way
        if (this.instanceofType && (!t1.instanceofType || !this.instanceofType.canContain(t1.instanceofType))) {
            return false;
        }
        // Ewww, this is a hack...
        if (this.genericType && (!this.genericType.canContain(t1.genericType))) {
            return false;
        }
        if (this.propsType && (!t1.propsType || !this.propsType.canContain(t1.propsType))) {
            return false;
        }
        return true;
    }
}
/**DO NOT USE! DO NOT INSTANTIATE! Only use in function declarations that use
 * `TransformerOnlyHint`
 * A tool the transformer uses to rewrite arguments of functions to Type objects
 */
export class TSCompileTimeType {
    constructor() {
        assert(false, 'tstype-transformer is required to transform calls using TSCompileTimeType<T>. It should NEVER be instantiated. Did you add the transformer?');
        this.name = 'DO NOT USE - TSCompileTimeType<T>';
        this.flags = 0;
    }
    canContain(t1) { return false; }
    equals(t) { return false; }
}
export function logT(tType) {
    assert(tType, 'tstype-transformer is required to transform calls to logT<T> to get the type object at compile time. We received undefined. Did you add the transformer?');
    console.log(tType);
}
/** Methods for getting Type singletons representing types, both runtime and TypeScript */
export class Types {
    /**Converts the TypeScript type T into a Type.
     * Requires the `tstype-transformer` to transform the TypeScript code to work */
    static forTS(tType) {
        assert(tType, 'tstype-transformer is required to transform calls to Types.forTS<T> to get the type object at compile time. We received undefined. Did you add the transformer?');
        return tType;
    }
    // TODO: This needs to be more pluggable (which properties to make structured, the depth
    // how to handle self referencing, etc)
    static _typeofObjectLike(o, structuredDepth = 0, noConstructorNameHint) {
        // Check if the object is Typed and let it define its type
        if (Typed.is(o)) { //Typed
            return Typed.get(o);
        }
        // Otherwise we need to infer all typing
        // StructuredType - Get the types of all props shallowly
        // TODO: This doesn't do any sort of prototype traversal and it only gets
        // own keys. This should be configurable in some way
        const shallowKeys = Object_keysAll(o);
        let structuredType = undefined;
        if (structuredDepth > 0 && shallowKeys.length > 0) {
            const props = shallowKeys
                .map(key => ({
                // TODO: This doesnt take into account
                // * Recursive trees
                // * "private" props (we might not want to match '__' prefixed props)
                [key]: this.typeOf(o[key], structuredDepth - 1)
            }))
                .reduce((acc, itm) => ({ ...acc, ...itm }), {});
            structuredType = new StructuredType(props);
        }
        // InstanceofType - Get if its an instanceof any class
        const proto = Object.getPrototypeOf(o);
        let instanceofType;
        if (!proto) {
            // No proto/null proto
            instanceofType = undefined;
        }
        else if (proto.hasOwnProperty('constructor')) {
            // Check for a constructor to use as the class. hasOwnProperty is useful here
            // so we don't use any inheritted .constructor properties in the prototype chain
            instanceofType = new InstanceofType(proto.constructor);
        }
        else {
            // We have a prototype but no .constructor which usually means
            // * A broken prototype inheritance implementation (unlikely in modern JS)
            // * Something else...
            //
            // Generators dont seem to set .constructor on their prototype
            // even though they should? at least accorind to ECMA 27.5.1.1, but maybe I'm most
            // likely interpretting it wrong?
            // In any case, we have the prototype, so make a stub to pass through to
            // InstanceofType that can be used to do instanceof/prototype comparisons with
            console.warn('StubPrototypeClass generated for ', proto);
            const stub = Object.create(null);
            stub.prototype = proto;
            // TODO Make sure the .name we generate is unique for different prototypes
            // TODO: Make this extendable/pluginable somehow
            // Test some properties of the class to see if we can define a slightly better
            // name
            if (noConstructorNameHint) {
                stub.name = noConstructorNameHint(o);
            }
            else {
                let tmpName;
                if (typeof proto[Symbol.toStringTag] === 'string') {
                    tmpName = proto[Symbol.toStringTag];
                }
                else if (proto.name) {
                    tmpName = proto.name;
                }
                else if (proto[Symbol.iterator]) {
                    tmpName = 'Iteratable';
                }
                else if (proto[Symbol.asyncIterator]) {
                    tmpName = 'AsyncIterable';
                }
                else {
                    tmpName = 'NoName';
                }
                stub.name = `${tmpName}_\$`; //_$ representing it's a stub of the name, and not the _real_ name
            }
            stub[InstanceofType.__stubSymbol] = true;
            instanceofType = new InstanceofType(stub);
        }
        return new ObjectType(undefined, instanceofType, structuredType);
    }
    /**Finds the Type for object o given the limited info at runtime*/
    static typeOf(o, structuredDepth = 1, noConstructorNameHint) {
        if (o === null) {
            return Types.forIntrinsic('null');
        }
        else if (typeof o === 'object' || typeof o === 'function') {
            return this._typeofObjectLike(o, structuredDepth, noConstructorNameHint);
        }
        else { // Use typeof for everything else
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
            // 'string', 'number', ...
            return {
                "string": () => Types.forLiteral(o),
                "number": () => Types.forLiteral(o),
                "boolean": () => Types.forLiteral(o),
                "bigint": () => Types.forLiteral(o),
                "undefined": () => Types.forIntrinsic('undefined'),
                "symbol": () => Types.forLiteral(o),
                "object": () => Types.forIntrinsic('object'),
                "function": () => Types.forRuntimeClass(Function)
            }[typeof o]();
        }
    }
    /** Can t0 contain the type t1 */
    static canContain(t0, t1) {
        return t0.canContain(t1);
    }
}
/** Memoized RawType */
Types.forRaw = memoize((name, flags = 0) => {
    return new RawType(name, flags);
});
/** Memoized IntrinsicType */
Types.forIntrinsic = memoize((name) => {
    return new IntrinsicType(name);
});
/** Memoized LiteralType */
Types.forLiteral = memoize((literal) => {
    return new LiteralType(literal);
});
/** UnionType */
Types.forUnion = (...types) => {
    return new UnionType(...types);
};
/** IntersectionType */
Types.forIntersection = (...types) => {
    return new IntersectionType(...types);
};
/** Deprecated: use ObjectType or make a new forInstanceofType function and similar for generic */
Types.forRuntimeClass = (cls, ...genericArgs) => {
    return new ObjectType(new GenericType(...genericArgs), new InstanceofType(cls), undefined);
};
/** StructuredType */
Types.forStructured = (properties) => {
    return new StructuredType(properties);
};
Types.forInstanceof = (cls) => {
    return new InstanceofType(cls);
};
Types.forGeneric = (...genericArgs) => {
    return new GenericType(...genericArgs);
};
Types.forObject = (gType, iType, sType) => {
    return new ObjectType(gType, iType, sType);
};
