import React from 'react';
import ErrorPrinter from './ErrorPrinter.jsx';
/**
 * An ErrorBoundary that can display a props.error as well as notify
 * when there's an error with .onError
 */
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined
        };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.   
        return { error };
    }
    componentDidCatch(error, errorInfo) {
        // Notify parent or log it
        const errFunc = typeof this.props.onError === 'function'
            ? this.props.onError
            : console.error;
        errFunc(error, errorInfo);
    }
    render() {
        if (!!this.state.error || !!this.props.error) {
            const e = this.state.error || this.props.error;
            return React.createElement(ErrorPrinter, { error: e });
        }
        return this.props.children;
    }
}
