import strHash from 'string-hash';
import React, { useState } from 'react';
export default function CastCtxInfo(props) {
    const { ctx, onSetCastId: _onSetCastId, className } = props;
    const onSetCastId = _onSetCastId || (() => { });
    const { castId, caster, data, type: dataType, previousThrew: wasThrow } = ctx;
    const castMap = caster.castMap;
    // TODO: Handle castId being undefined
    const match = castMap.getMatch(castId);
    const matches = Array.from(castMap.match(data, dataType));
    const [condensed, setCondensed] = useState(true);
    const colors = ['#000000', '#07433F', '#0A1435', '#1E2E0B', '#1F3C88', '#1F6F88', '#1F8843', '#282724', '#2A3D47', '#2B2A47', '#3A1F88', '#3E472A', '#411231', '#472A2A', '#47382A', '#535353', '#661F88', '#6D881F', '#6F6849', '#881F51', '#883F1F', '#886B1F'];
    const colorForType = (t) => colors[strHash(t.name) % colors.length];
    const styleForType = (t) => (!t ? {} : {
        backgroundColor: colorForType(t),
    });
    const dtStyle = styleForType(dataType);
    const mtStyle = typeof match?.typeOrCmpFn === 'function'
        ? {}
        : styleForType(match?.typeOrCmpFn);
    return (React.createElement("span", { className: `${className} ${condensed ? 'condensed' : ''}` },
        condensed
            ? (React.createElement("span", { onClick: () => setCondensed(!condensed) },
                React.createElement("span", { style: dtStyle, className: "className" }, dataType?.name.slice(0, 1)),
                React.createElement("span", { style: mtStyle, className: "className" }, match?.typeOrCmpFn.name.slice(0, 1))))
            : (React.createElement(React.Fragment, null,
                React.createElement("button", { onClick: () => setCondensed(!condensed) }, "Close"),
                wasThrow ? 'threw ' : 'returned ',
                React.createElement("span", { style: dtStyle, className: "className" }, dataType?.name),
                ' matching ',
                React.createElement("select", { style: mtStyle, className: "className", value: match?.id, onChange: (e) => onSetCastId(e.target.value) }, matches.map((m) => (React.createElement("option", { key: m.id, value: m.id }, m.typeOrCmpFn.name)))),
                ' as ')),
        React.createElement("select", { style: mtStyle, className: "className", value: match?.id, onChange: (e) => onSetCastId(e.target.value) }, matches.map((m) => (React.createElement("option", { key: m.id, value: m.id }, m.value.name || '<no function name>'))))));
}
