import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { evaluate } from '@mapcast/core-data-tree';
import scss from './FileREPL.module.scss';
import REPLIO from './REPLIO.jsx';
import { useInspectablePromise } from '@mapcast/ui-react-core';
function useInspectableAsyncFn(fn) {
    const p = useMemo(() => fn(), [fn]);
    return useInspectablePromise(p);
}
/**
 * REPLIO mirrored to a File object (uses File contents, extension to choose kernel
 * and updates File contents).
 */
export default function FileREPL(props) {
    const { data: file } = props;
    // File properties
    const ctime = useInspectableAsyncFn(file.ctime);
    const mtime = useInspectableAsyncFn(file.mtime);
    const name = file.path;
    const contents = useInspectableAsyncFn(file.contents);
    const onInput = (newInput) => {
        // tODO: Debounce this, and make sure that old writes are cancelled or don't
        // clobber newer writes
        //file.setContents(v);
    };
    // Language derived from path
    const languages = ['none', 'javascript', 'markdown'];
    const ext = file.path.includes('.')
        ? file.path.slice(file.path.lastIndexOf('.') + 1)
        : undefined;
    console.log(ext);
    const extToLanguage = {
        'md': 'markdown',
        'js': 'javascript'
    };
    const [selectedLanguage, setSelectedLanguage] = useState(ext
        ? (extToLanguage[ext] || languages[0])
        : languages[0]);
    // Select the kernel for REPL based on language type
    const kernel = {
        'javascript': evaluate.bind(undefined)
    }[selectedLanguage];
    // UI controls
    const [expanded, setExpanded] = useState(false);
    const [showLastModified, setShowLastModified] = useState(true);
    return (React.createElement("pre", { className: scss.repl },
        React.createElement("header", { className: "meta" },
            React.createElement("button", { className: "expandButton", onClick: () => setExpanded(!expanded) }, '+'),
            React.createElement("div", null,
                React.createElement("input", { type: "text", defaultValue: name }),
                React.createElement("select", { value: selectedLanguage, onChange: (e) => setSelectedLanguage(e.target.value) }, languages.map(l => (React.createElement("option", { value: l, key: l }, l))))),
            React.createElement("div", { onClick: () => setShowLastModified(!showLastModified) }, showLastModified
                ? React.createElement("p", null,
                    "Last Modified: ",
                    dayjs(mtime.$inspect.syncValueT(Date.now())).format('MMM D YYYY, HH:mm:ss'))
                : React.createElement("p", null,
                    "Created: ",
                    dayjs(ctime.$inspect.syncValueT(Date.now())).format('MMM D YYYY, HH:mm:ss')))),
        expanded && (React.createElement(REPLIO, { cmOptions: {
                mode: selectedLanguage === 'none' ? undefined : selectedLanguage,
                viewportMargin: 2,
                theme: 'material-darker'
            }, kernel: kernel, input: contents.$inspect.syncValueT(''), onInput: onInput }))));
}
