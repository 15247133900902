import { Types } from '@mapcast/core-type';
import { CastMap } from '@mapcast/core-cast';
Types; // To prevent import from culling
export const peekMap = new CastMap();
const pm = peekMap;
// Files
pm.addT(() => '🖼️', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("FILE"), "image": Types.forIntrinsic("any") })));
pm.addT(() => '📄', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("FILE") })));
pm.addT(() => '📁', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("DIRECTORY") })));
pm.addT(() => '💾', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("BLOCK_DEVICE") })));
pm.addT(() => '⌨', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("CHARACTER_DEVICE") })));
pm.addT(() => '📩', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("FIFO") })));
pm.addT(() => '🌐', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("SOCKET") })));
pm.addT(() => '⤴', Types.forObject(undefined, undefined, Types.forStructured({ "__fileType": Types.forLiteral("SYMBOLIC_LINK") })));
// TODO: Dont uncomment this, it wont work
// im.addT<IFileLike>(<span>{'📄'}</span>);
pm.addT((a) => {
    return a?.length > 0
        ? '[...]'
        : '[]';
}, Types.forObject(Types.forGeneric(Types.forIntrinsic("any")), Types.forInstanceof(Array), undefined));
pm.addT(() => 'undefined', Types.forIntrinsic("undefined"));
pm.addT(() => 'null', Types.forIntrinsic("null"));
pm.addT((o) => {
    if (o === undefined || o === null) {
        return "<string>";
    }
    let ret = o;
    if (o.length > 50) {
        ret = o.slice(0, 20) + '...' + o.slice(-20);
    }
    return `"${ret}"`;
}, Types.forUnion(Types.forIntrinsic("string"), Types.forObject(undefined, Types.forInstanceof(String), undefined)));
pm.addT(() => 'Symbol()', Types.forIntrinsic("symbol"));
pm.addT((o) => '' + o, Types.forUnion(Types.forLiteral(false), Types.forLiteral(true)));
pm.addT((o) => '' + o, Types.forIntrinsic("bigint"));
pm.addT((o) => '' + o, Types.forIntrinsic("number"));
pm.addT(() => '()=>{}', Types.forObject(undefined, Types.forInstanceof(Function), undefined));
pm.addT((o) => {
    if (!o === undefined) {
        return '{}';
    }
    let namePrefix = '';
    const proto = Object.getPrototypeOf(o);
    // TODO: Types.typeOf does a similar thing, align these two implementations
    if (!proto) {
        // Null proto
        namePrefix = '<null>';
    }
    else if (proto.hasOwnProperty('constructor') && proto.constructor.name) {
        namePrefix = proto.constructor.name;
    }
    else if (typeof proto[Symbol.toStringTag] === 'string') {
        namePrefix = proto[Symbol.toStringTag];
    }
    else if (proto.name) {
        namePrefix = proto.name;
    }
    else if (proto[Symbol.iterator]) {
        namePrefix = 'Iteratable';
    }
    else if (proto[Symbol.asyncIterator]) {
        namePrefix = 'AsyncIterable';
    }
    if (namePrefix === 'Object') {
        namePrefix = undefined;
    }
    return `${namePrefix ? namePrefix + ' ' : ''}{}`;
}, Types.forIntrinsic("object"));
pm.addT(() => '{}', Types.forIntrinsic("any"));
