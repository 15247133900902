import { Array_fromAsync } from '@mapcast/core';
import { Types } from '@mapcast/core-type';
import { Path } from '@mapcast/core-path';
import { IResolver, PropResolver } from '@mapcast/core-data-tree';
import FeedParser from 'feedparser';
/**
 * Resolves RSS, RDF, and Atom feeds
 * @todo Error should have special subclass
 * @todo Should not add http:// to endpoint
 * @todo Should do something with ,children (at least a [])
 */
export class FeedResolver2 extends IResolver {
    /**
     * @param fetchImpl Implementation of fetch()
     */
    constructor(fetchImpl, url, feedParserOptions) {
        super();
        this.__fetchImpl = fetchImpl;
        this.__url = url;
        this.__fpOptionOverrides = feedParserOptions;
        this.__readItemResolvers = {};
    }
    /**AsyncGenerator for every item in the RSS feed*/
    async *_feedItems(url) {
        const feedParser = new FeedParser({
            feedurl: url,
            ...this.__fpOptionOverrides
        });
        const resp = await this.__fetchImpl(url);
        if (!resp.ok) {
            throw new Error(`Fetch failed with '${resp.status}'`);
        }
        const ret = [];
        // TODO: For browsers
        // Eventually, we will want to use .pipeTo or .pipeThrough in browsers when
        // they implement that, for now we have to kind of do it ourselves
        const readable = resp.body;
        if (!readable.pipe) {
            // eslint-disable-next-line no-inner-declarations
            async function pipePolyfill(writable) {
                const lockedReader = this.getReader();
                let chunk;
                while (chunk = await lockedReader.read()) {
                    const { value, done } = chunk;
                    console.log('piping chunk', chunk);
                    if (done) {
                        break;
                    }
                    writable.write(value);
                }
            }
            readable.pipe = pipePolyfill;
        }
        feedParser.on('error', (error) => ret.push(error));
        readable.pipe(feedParser);
        // Wait for the stream to be readable
        await (new Promise((resolve, reject) => {
            feedParser.on('readable', () => { resolve(undefined); });
        }));
        let item; // Will have a .meta on it too!
        while (item = feedParser.read()) {
            this.__readItemResolvers[item.title] = new PropResolver(item);
            yield item;
        }
    }
    async children(path) {
        if (path === '' || path === '/') {
            return (await Array_fromAsync(this._feedItems(this.__url)))
                .map((i) => ({
                name: i.title,
                typeHint: Types.typeOf(i)
            }));
        }
        const [head, rest] = Path.head(path);
        if (this.__readItemResolvers[head]) {
            const r = this.__readItemResolvers[head];
            return r.children(rest);
        }
        return [];
    }
    hasChildren(path) {
        if (path === '' || path === '/') {
            return true;
        }
        const [head, rest] = Path.head(path);
        if (this.__readItemResolvers[head]) {
            const r = this.__readItemResolvers[head];
            return r.hasChildren(rest);
        }
        return false;
    }
    typeHint(path) {
        const [head, rest] = Path.head(path);
        if (this.__readItemResolvers[head]) {
            const r = this.__readItemResolvers[head];
            return r.typeHint(rest);
        }
        return Types.forIntrinsic('any');
    }
    isCircular(path) {
        const [head, rest] = Path.head(path);
        if (this.__readItemResolvers[head]) {
            const r = this.__readItemResolvers[head];
            return r.isCircular(rest);
        }
        return false;
    }
    // == verbs ==
    options(path) {
        const [head, rest] = Path.head(path);
        if (this.__readItemResolvers[head]) {
            const r = this.__readItemResolvers[head];
            return r.options(rest);
        }
        return ['get'];
    }
    get(path) {
        const [head, rest] = Path.head(path);
        if (this.__readItemResolvers[head]) {
            const r = this.__readItemResolvers[head];
            return r.get(rest);
        }
        return {};
    }
}
