import { Types, ObjectType, IntrinsicType, InstanceofType, GenericType, Typed } from './Type.js';
// .[TypedSymbol]() functions that would be nice to install on default types
function Array_Typed_type() {
    if (this.length === 0) {
        return new ObjectType(new GenericType(new IntrinsicType('any')), new InstanceofType(Array));
    }
    const firstType = Types.typeOf(this[0]);
    if (this.length === 1) {
        return new ObjectType(new GenericType(firstType), new InstanceofType(Array));
    }
    // TODO: This could uses UnionTypes to give a better type
    const allSame = this.every((item) => Types.typeOf(item) === firstType);
    return new ObjectType(new GenericType(allSame ? firstType : new IntrinsicType('any')), new InstanceofType(Array));
}
/** Installs an [Typed.typeSymbol] function onto Array.prototype */
export function installArrayPrototypeExtension() {
    Array.prototype[Typed.typeSymbol] = Array_Typed_type;
}
/** Installs all [Typed.typeSymbol] prototype extensions */
export function installAllPrototypeExtensions() {
    installArrayPrototypeExtension();
}
