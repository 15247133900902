import { Readable } from 'stream';
import memoize from 'lodash/memoize.js';
import * as png from 'pngjs';
import { File, HTTPFile } from './File.js';
/**
 * Represents a 2D array of pixels
 */
export class IImage {
}
class ReadableFrom extends Readable {
    constructor(str) {
        super();
        this.str = str;
        this.sent = false;
    }
    _read() {
        if (!this.sent) {
            this.push(this.str);
            this.sent = true;
        }
        else {
            this.push(null);
        }
    }
}
export class PngImage {
    constructor(buffer, readStream) {
        this.__buffer = buffer;
        this.__readStream = readStream;
        this.__getPngStream = memoize(this.__getPngStream.bind(this));
        this.__getPngMetadata = memoize(this.__getPngMetadata.bind(this));
        this.__getPngData = memoize(this.__getPngData.bind(this));
    }
    __getPngStream() {
        // memoized in constructor
        return this.__readStream.pipe(new png.PNG());
    }
    async __getPngMetadata() {
        // memoized in constructor
        return new Promise((resolve, reject) => {
            const pngStream = this.__getPngStream();
            pngStream.on("metadata", resolve);
            pngStream.on("error", reject);
        });
    }
    async __getPngData() {
        // memoized in constructor
        return new Promise((resolve, reject) => {
            const pngStream = this.__getPngStream();
            pngStream.on("parsed", resolve);
            pngStream.on("error", reject);
        });
    }
    async width() {
        const metadata = await this.__getPngMetadata();
        return metadata.width;
    }
    async height() {
        const metadata = await this.__getPngMetadata();
        return metadata.height;
    }
    async buffer() {
        return new Buffer(await this.__getPngData());
    }
    async rawBuffer() {
        return this.__buffer;
    }
}
export const FileContainingPngImage = File.contains((file) => {
    const imgFile = file;
    imgFile.image = async function image() {
        const contents = await this.contents();
        const copyConvert = new Buffer(new Uint8Array(contents));
        const contentsStream = new ReadableFrom(copyConvert);
        const image = new PngImage(copyConvert, contentsStream);
        return image;
    };
    return imgFile;
});
/**
 * An image who's source of truth is HTTP(S) but gets persisted to the local disk
 */
export class HTTPImage extends HTTPFile {
}
