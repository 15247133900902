import React from 'react';
import * as ReactDOM from "react-dom";
import { installAllPrototypeExtensions } from '@mapcast/core-type';
import { CastToElContext } from '@mapcast/ui-react-core';
import Main from './elements/Main.jsx';
import { castMap } from './config/index.jsx';
import './index.scss';
// TODO: This should probably only be added if we're not being used as a library
installAllPrototypeExtensions();
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(CastToElContext.Provider, { value: castMap },
        React.createElement(Main, null))), document.getElementById('root'));
