import { promisify } from 'util';
/**Promisitify an instance of webdav-fs to function like fs/promises
 * including correcting API deviations between the two*/
export function wfsPromisify(webdavfs) {
    //access(path[, mode])
    //appendFile(path, data[, options])
    //chmod(path, mode)
    //chown(path, uid, gid)
    //copyFile(src, dest[, mode])
    //cp(src, dest[, options])
    //lchmod(path, mode)
    //lchown(path, uid, gid)
    //lutimes(path, atime, mtime)
    //link(existingPath, newPath)
    //lstat(path[, options])
    const mkdirP = promisify(webdavfs.mkdir.bind(webdavfs));
    //mkdtemp(prefix[, options])
    //open(path, flags[, mode])
    //opendir(path[, options])
    const readdirP = promisify(webdavfs.readdir.bind(webdavfs));
    const readFileP = promisify(webdavfs.readFile.bind(webdavfs));
    //readlink(path[, options])
    //realpath(path[, options])
    const renameP = promisify(webdavfs.rename.bind(webdavfs));
    const rmdirP = promisify(webdavfs.rmdir.bind(webdavfs));
    //rm(path[, options])
    const statP = promisify(webdavfs.stat.bind(webdavfs));
    //symlink(target, path[, type])
    //truncate(path[, len])
    const unlinkP = promisify(webdavfs.unlink.bind(webdavfs));
    //utimes(path, atime, mtime)
    //watch(filename[, options])
    const writeFileP = promisify(webdavfs.writeFile.bind(webdavfs));
    return {
        //access(path[, mode])
        //appendFile(path, data[, options])
        //chmod(path, mode)
        //chown(path, uid, gid)
        //copyFile(src, dest[, mode])
        //cp(src, dest[, options])
        //lchmod(path, mode)
        //lchown(path, uid, gid)
        //lutimes(path, atime, mtime)
        //link(existingPath, newPath)
        //lstat(path[, options])
        mkdir(path, options) {
            if (options && (options.recursive || options.mode)) {
                throw new Error('webdav-fs/shim not currently supported (.recursive or .mode)');
            }
            return mkdirP(path);
        },
        // mkdtemp(prefix[, options])
        // open(path, flags[, mode])
        // opendir(path[, options])
        readdir(path, options) {
            if (options && (options.encoding)) {
                throw new Error('webdav-fs/shim not currently supported (.encoding)');
            }
            const webdavFsMode = (options && options.withFileTypes)
                ? "stat"
                : "node";
            return readdirP(path, webdavFsMode);
        },
        readFile(path, options) {
            if (options && (options.flag)) {
                throw new Error('webdav-fs/shim not currently supported (.flag)');
            }
            const encoding = (options && options.encoding)
                ? options.encoding
                // nodejs defaults to returning a buffer for readFile which is "binary"
                // for webdavFs
                : "binary";
            return readFileP(path, encoding);
        },
        // readlink(path[, options])
        // realpath(path[, options])
        rename(oldPath, newPath) {
            return renameP(oldPath, newPath);
        },
        rmdir(path, options) {
            if (options && (options.force || options.maxRetries || options.recursive || options.retryDelay)) {
                throw new Error('webdav-fs/shim not currently supported .force, .maxRetries, .recursive, .retryDelay');
            }
            return rmdirP(path);
        },
        // rm(path[, options])
        stat(path, options) {
            return statP(path);
        },
        // symlink(target, path[, type])
        // truncate(path[, len])
        unlink(path) {
            return unlinkP(path);
        },
        // utimes(path, atime, mtime)
        // watch(filename[, options])
        writeFile(path, data, options) {
            if (options && (options.mode)) {
                throw new Error('webdav-fs/shim not currently supported .mode');
            }
            if (options && (options.flag !== 'w' || options.flag !== 'w+')) {
                // https://github.com/perry-mitchell/webdav-fs/issues/46
                throw new Error('webdav-fs/shim not currently supported .flag when not w or w+ mode');
            }
            const encoding = (options && options.encoding)
                ? options.encoding
                : "utf8";
            return writeFileP(path, data, encoding);
        }
    };
}
