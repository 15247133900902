/** Array.from for AsyncIterable */
export async function Array_fromAsync(asyncIterator) {
    const arr = [];
    for await (const i of asyncIterator) {
        arr.push(i);
    }
    return arr;
}
/** Takes a tagged string function arguments and returns a normal string */
export function unTag(strings, ...args) {
    return strings.reduce((acc, itm, idx) => acc + itm + (args[idx] || ''), '');
}
/** TypeScript guard for is an Iterable */
export function isIterable(t) {
    return typeof t === 'object' && t !== null && !!t[Symbol.iterator];
}
/** TypeScript guard for is an AsyncIterable */
export function isAsyncIterable(t) {
    return typeof t === 'object' && t !== null && !!t[Symbol.asyncIterator];
}
export function nameFn(name, fn) {
    return {
        [name]: ((...args) => fn(...args))
    }[name];
}
