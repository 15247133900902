import React, { useRef, useEffect } from 'react';
import codemirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material-darker.css';
// Supported languages
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/markdown/markdown.js';
import { usePrevious } from './hooks/general.jsx';
/**
 * @todo onChange and onLoad only work if passed on component creation
 */
export default function CodeMirror(props) {
    const { options: _options, onChange, onLoad } = props;
    const options = _options || {};
    const prevOptions = usePrevious(options);
    //const prevOnChange = usePrevious(onChange);
    const ref = useRef(null);
    const cmRef = useRef();
    useEffect(() => {
        if (ref.current && !cmRef.current) {
            cmRef.current = codemirror.fromTextArea(ref.current, options);
            if (typeof onChange === 'function') {
                cmRef.current.on('change', onChange);
            }
            if (typeof onLoad === 'function') {
                onLoad(cmRef.current);
            }
            // this.codeMirror = codeMirrorInstance.fromTextArea(this.textareaNode, this.props.options);
            // this.codeMirror.on('cursorActivity', this.cursorActivity);
            // this.codeMirror.on('focus', this.focusChanged.bind(this, true));
            // this.codeMirror.on('blur', this.focusChanged.bind(this, false));
            // this.codeMirror.on('scroll', this.scrollChanged);
            // this.codeMirror.setValue(this.props.defaultValue || this.props.value || '');
        }
    });
    useEffect(() => {
        if (!cmRef.current) {
            return;
        }
        for (const [k, v] of Object.entries(options)) {
            if (prevOptions?.[k] !== v) {
                console.log(k, 'changed');
                cmRef.current.setOption(k, v);
            }
        }
    }, [options]);
    return (React.createElement("textarea", { ref: ref }));
}
