import { strict as assert } from 'assert';
import { Types } from '@mapcast/core-type';
/**Holds a mapping of type or comparison function to some other value type. Allows
 * for querying and returning an iterator of multiple matches exhaustively
 * @todo Allow match to specify two types, input type and output type to filter
 * by
 * @todo Allow for multiple casts/finding a TFrom and TTo even if there's more
 * than 1 cast between them. Doing this while preserving determinism and precedence
 * will be tough...
 */
export class CastMap {
    constructor(debug = false) {
        this.__castValById = {};
        this.__idx = 0;
        this.__debugId = null;
        this.__debugName = '';
    }
    add(arg0, val) {
        if (typeof arg0 === 'function' && arg0.name.trim() === '') {
            console.warn('compareFn added to CastMap does not have a name');
        }
        const id = `${arg0.name}_${this.__idx}`;
        this.__castValById[id] = [arg0, val];
        this.__idx += 1;
        return id;
    }
    addT(val, type) {
        assert(type, 'tstype-transformer is required to transform calls to CastMap.addT<T> to get the type object at compile time. We received undefined. Did you add the transformer?');
        return this.add(type, val);
    }
    has(id) {
        return !!this.get(id);
    }
    get(id) {
        return this.__castValById[id];
    }
    /**Returns a match() object by id, instead of just the value*/
    getMatch(id) {
        const [typeOrCmpFn, value] = this.get(id);
        return { id, typeOrCmpFn, value };
    }
    /**For a given type, finds potential casts, can return multiple types. Will not
     * yield if there were no matches
     */
    *match(o, oType) {
        // const doDebug = !!this.__debugName;
        // if (doDebug) {
        //   console.groupCollapsed(`== ${this.__debugName} CastMap#match() ==`);
        //   console.log('oType', oType);
        //   console.log('Type_canContainFlags', Type_canContainFlags);
        //   console.log('Type_canContainCommon', Type_canContainCommon);
        // }
        // Get the matching elements from typeMap
        for (const [castId, typeAndCastVal] of Object.entries(this.__castValById)) {
            const [typeToTestOrCmpFn, castVal] = typeAndCastVal;
            const testPass = typeof typeToTestOrCmpFn === 'function'
                ? typeToTestOrCmpFn(o)
                : typeToTestOrCmpFn.canContain(oType);
            // if (doDebug && castId === this.__debugId) {
            //   const debugTestName = `${typeToTestOrCmpFn.name}${typeof typeToTestOrCmpFn === "function" ? "()" : ""}`;
            //   const debugTestPassed = testPass ? '== MATCH ==' : 'no match';
            //   console.log(`TEST ${debugTestName} - ${debugTestPassed}`, typeToTestOrCmpFn);
            // }
            if (testPass) { // Our type can be contained by the type to test against
                // if (doDebug) {
                //   console.groupEnd();
                // }
                yield {
                    id: castId,
                    typeOrCmpFn: typeToTestOrCmpFn,
                    value: castVal
                };
            }
        }
    }
    /** *match but returns only the first match. If none, returns null */
    getFirstMatch(o, oType) {
        const matchItr = this.match(o, oType);
        const matchVal = matchItr.next().value;
        if (!matchVal) {
            return null; // No match
        }
        return matchVal;
    }
    /** *match but returns only the first value. If none, returns null */
    getFirstValue(o, oType) {
        const match = this.getFirstMatch(o, oType);
        if (!match) {
            return null; // No match
        }
        return match.value;
    }
}
/**Performs a cast on a CastMap containing functions casting T to TCastTo, potentially
 * performing multiple casts
 * @todo Handle Types.typeOf throwing
 * @todo ctx should be TCtxExtra and not object
 */
export class FnCaster {
    constructor(castMap) {
        this.castMap = castMap;
    }
    /**Single cast, no try/catch*/
    _castUnsafe(data, type, ctx, castId, defaultCastFn) {
        let castFn;
        if (castId) {
            const match = this.castMap.getMatch(castId);
            assert(match, `No castMap match was found with id "${castId}"`);
            castFn = match.value;
        }
        else {
            const match = this.castMap.getFirstMatch(data, type);
            castId = match?.id ?? null; // set castId to the found id
            assert(match || defaultCastFn, `No castMap match was found for data and type as no default provided`);
            castFn = match ? match.value : defaultCastFn;
        }
        // Augment ctx with information about what we're casting
        const outCtx = ctx;
        outCtx.type = type;
        outCtx.data = data;
        outCtx.castId = castId;
        outCtx.caster = this;
        // Call the castFn with the data
        return castFn(data, outCtx);
    }
    /**Performs a normal SingleCaster.cast() but will cast the Error output again
     * if it throws. If the second cast throws, we will let it throw
     */
    cast(inData, inTypeOverride, ctx, castId, defaultCastFn) {
        const data = inData;
        const type = inTypeOverride || Types.typeOf(inData);
        // Try first cast
        try {
            return this._castUnsafe(data, type, ctx, castId, defaultCastFn);
        }
        catch (e) {
            inData = e;
        }
        // Recast any errors
        try {
            // TODO: Error casts have no controllable id, at least right now
            const errorCastCtx = {
                ...ctx,
                previousThrew: true
            };
            return this._castUnsafe(data, type, errorCastCtx, undefined, defaultCastFn);
        }
        catch (e) {
            // TODO: We should really be showing way more data about why the cast failed
            // (what types were involved, etc, somehow)
            'e';
            throw e; // Rethrow after bookkeeping
        }
    }
}
