import React, { useState, useEffect } from 'react';
import { stringify, configure } from 'safe-stable-stringify';
// Limits amount of entries serialized but not the entire string length
const _saferStringify = configure({
    maximumDepth: 10,
    maximumBreadth: 15
});
const saferStringify = (...args) => {
    const str = _saferStringify(...args);
    if (str.length > 50000) {
        // Prevent massive strings from displaying
        const diff = str.length - 50000;
        return str.slice(0, 49900)
            + '... <hidden> ...'
            + str.slice(-1000)
            + `\n\n...and ${diff} more hidden characters\n`;
    }
    return str;
};
/**Converts any object to a string safely handling circular references and extremely
 * large objects. Is meant as a last resort to print something to the screen so
 * take care!
 */
export function toDebugStringSafe(o, showAll = false) {
    const replacer = (k, v) => {
        if (typeof v === 'function') {
            // Do not toString the function, it seems to be not very performant for
            // bigger functions
            return `function ${v.name}(){}`;
        }
        return v;
    };
    if (typeof o === 'string') {
        // Return as-is so it pretty prints with newlines and such
        return o;
    }
    if (typeof o === 'undefined') {
        return 'undefined';
    }
    if (typeof o === 'function') {
        // Can't be stringified with JSON.stringify
        return `function ${o.name}(){}`;
    }
    const stringifyFn = showAll ? stringify : saferStringify;
    return stringifyFn(o, replacer, 2);
}
/**Minimal debug printer meant to dump an object to a string, safely and without
 * catching on weird corner cases like big objects or circular references
 */
export default function SafeDebugPrinter(props) {
    const { data } = props;
    const [showAll, setShowAll] = useState(false);
    const [debugStr, setDebugStr] = useState(toDebugStringSafe(data, showAll));
    useEffect(() => {
        setDebugStr(toDebugStringSafe(data, showAll));
    }, [data, showAll]);
    return (React.createElement("pre", null,
        debugStr,
        React.createElement("button", { onClick: () => setShowAll(true) }, "Show All")));
}
