import React, { useState, useEffect, useRef } from 'react';
import { CodeMirror, CastToEl, ErrorBoundary, usePrevious } from '@mapcast/ui-react-core';
import scss from './REPLIO.module.scss';
/** A REPL-esque text box that takes input and optionally displays output. */
export default function REPLIO(props) {
    const { input: externalInput, kernel, onInput, cmOptions, resolve } = props;
    const prevExternalInput = usePrevious(externalInput);
    const [input, setInput] = useState(externalInput || '');
    function setExternalInput(newInput) {
        setInput(newInput);
        if (cm.current) {
            cm.current.setValue(newInput);
        }
    }
    const cm = useRef();
    if (externalInput !== undefined // Has a value
        && externalInput !== prevExternalInput // Input changed
        && externalInput !== input) { // And it doesn't match our internal input
        setExternalInput(externalInput);
    }
    // Codemirror handling
    const onCMLoad = (_cm) => {
        cm.current = _cm;
        _cm.setValue(input);
    };
    const onCMChange = (cm) => {
        const v = cm.getValue();
        setInput(v);
        if (typeof onInput === 'function') {
            onInput(v);
        }
    };
    // Kernel evaluation + error handling
    const inputSanitized = input.trim();
    const [inputError, setInputError] = useState(undefined); // An error with running the input (SyntaxError, other runtime error)
    const [inputResolved, setInputResolved] = useState([]);
    const [outputError, setOutputError] = useState(undefined); // Error when displaying resolved input data (so React doesn't unmount us)
    const [outputKey, setOutputKey] = useState(Math.random()); // Used to cycle the output element when it gets an error
    useEffect(() => {
        // Unset output error if we're going to try a new input
        if (!!outputError) {
            setOutputError(undefined);
            setOutputKey(Math.random()); // Cycle key
        }
        // Run the javascript string and error handle it
        let ir;
        setInputError(undefined);
        if (typeof kernel !== 'function') {
            return; // Nothing to do...
        }
        try {
            ir = kernel(inputSanitized);
        }
        catch (e) {
            setInputError(e);
            console.error(e);
            ir = undefined;
        }
        console.log('input resolved', ir);
        setInputResolved(() => ir);
    }, [input]);
    const onOutputError = (error, errorInfo) => {
        setOutputError(error);
    };
    const activeError = inputError || outputError;
    return (React.createElement("div", { className: scss.replio },
        React.createElement("div", { className: "input" },
            React.createElement(CodeMirror, { style: { height: 'auto' }, options: cmOptions, onChange: onCMChange, onLoad: onCMLoad })),
        typeof kernel === 'function' && (React.createElement("div", { className: `output ${(activeError) ? 'error' : ''}`, key: outputKey },
            React.createElement(ErrorBoundary, { onError: onOutputError },
                React.createElement(CastToEl, { className: "outputEl", data: activeError || inputResolved, ctx: {
                        input,
                        path: input,
                        setInput: setExternalInput,
                        resolve: resolve
                    } }))))));
}
