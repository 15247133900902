/**Returns a utility that applies path to all calls to resolver functions*/
export function resolverAtPath(resolver, path) {
    return {
        isIResolver: true,
        hasChildren: () => resolver.hasChildren(path),
        children: () => resolver.children(path),
        isCircular: () => resolver.isCircular(path),
        typeHint: () => resolver.typeHint(path),
        get: () => resolver.get(path),
        add: () => resolver.add(path),
        set: (path, value) => resolver.set(path, value),
        delete: () => resolver.delete(path),
        options: () => resolver.options(path),
        listen: (path, cb) => resolver.listen(path, cb),
        unlisten: (path, cb) => resolver.listen(path, cb),
    };
}
