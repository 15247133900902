import path from 'path';
import memoize from 'lodash/memoize.js';
import { Dataset } from '@mapcast/core';
/**Converts a State or DirEnt to a FileType
 */
export function EntToFileType(ent) {
    return ent.isFile()
        ? 'FILE'
        : ent.isDirectory()
            ? 'DIRECTORY'
            : ent.isSymbolicLink()
                ? 'SYMBOLIC_LINK'
                : ent.isSocket()
                    ? 'SOCKET'
                    : ent.isBlockDevice()
                        ? 'BLOCK_DEVICE'
                        : ent.isCharacterDevice()
                            ? 'CHARACTER_DEVICE'
                            : ent.isFIFO()
                                ? 'FIFO'
                                : 'UNKNOWN';
}
/**
 * Represents a file on a filesystem
 * @todo Relative paths
 * @todo This should be called "ResolverFile" as there is now way to make a general
 * purpose File implementation
 * * It should be as painless and lazy as possible, but FSResolver pulls some things
 * sync-ly that we want to ensure are not async. For indexing, speed, and UI showing
 * purposes. We have to pass those to the constructor. It's tightly coupled to
 * FSResolver this way
 * * Files in other contexts might have other properties, other file systems, other
 * transport/access mechanisms
 */
export class File extends Dataset {
    constructor(path, fsImpl) {
        super();
        this.__fileType = "UNKNOWN";
        this.__fsImpl = fsImpl;
        this.__path = path;
        this.__getFileRealPath = memoize(this.__getFileRealPath.bind(this));
        this.__getFileStat = memoize(this.__getFileStat.bind(this));
        this['$$children'] = this.$$children;
        Dataset.wrapAsyncProps(['contents', 'type', 'ctime', 'mtime', 'size', 'user'], this);
        Dataset.wrapAsyncProps(['children', '$$children', 'realPath'], this);
        //asyncGenProps([''], this);
    }
    /**Allows us to type the contents of the file more explicitly as another type*/
    static contains(containsFn) {
        class FileContainsXXX extends File {
            static fromFile(file) {
                const fcxxx = containsFn(file);
                return fcxxx;
            }
            constructor(...args) {
                super(...args);
                containsFn(this);
            }
        }
        return FileContainsXXX;
    }
    get props() {
        return super.props.concat(['type', 'path', 'name', 'ctime', 'mtime', 'size', 'user']);
    }
    get id() {
        return this.__path;
    }
    async __getFileRealPath() {
        // memoized in constructor
        return await this.__fsImpl.realpath(this.__path);
    }
    async realPath() {
        return await this.__getFileRealPath();
    }
    // TODO: Still have to implement the ones below
    // dev: 2114,
    // ino: 48064969,
    // mode: 33188,
    // nlink: 1,
    // gid: 100,
    // rdev: 0,
    // blksize: 4096,
    // blocks: 8,
    // atimeMs: 1318289051000.1,
    // mtimeMs: 1318289051000.1,
    // ctimeMs: 1318289051000.1,
    // birthtimeMs: 1318289051000.1,
    // atime: Mon, 10 Oct 2011 23:24:11 GMT,
    // birthtime: Mon, 10 Oct 2011 23:24:11 GMT
    async __getFileStat() {
        // memoized in constructor
        return await this.__fsImpl.stat(this.__path);
    }
    async ctime() {
        const stat = await this.__getFileStat();
        // ctime: Mon, 10 Oct 2011 23:24:11 GMT,
        return stat.ctime;
    }
    async mtime() {
        const stat = await this.__getFileStat();
        // mtime: Mon, 10 Oct 2011 23:24:11 GMT,
        return stat.mtime;
    }
    async user() {
        const stat = await this.__getFileStat();
        // uid: 85,
        const user = User.new(stat.uid);
        return user;
    }
    /**
     * Size in bytes
     * @returns {Number} number of bytes
     */
    async size() {
        const stat = await this.__getFileStat();
        // size: 527,
        return stat.size;
    }
    /**
     * Gets the type of the File
     */
    async type() {
        const stat = await this.__getFileStat();
        return EntToFileType(stat);
    }
    // TODO: Memoize?
    async children() {
        // TODO: Check if we have stat's yet, and don't do a scan if we have them
        try {
            const children = await this.__fsImpl.readdir(this.__path);
            return children;
            // const childrenObj = {} as { [k: string]: File };
            // for (const c of children) {
            //   childrenObj[c] = new File(`${this.path}${c}`, this.__fsImpl);
            // }
            // return childrenObj;
        }
        catch (e) {
            if (e.code === 'ENOTDIR') {
                return []; // No items, it was not a directory
            }
            throw e; // Rethrow otherwise
        }
    }
    async $$children() {
        // Get the child names
        const children = await this.children();
        const childrenObj = {};
        for (const c of children) {
            childrenObj[c] = new File(`${this.path}/${c}`, this.__fsImpl);
        }
        return childrenObj;
    }
    get basename() {
        return path.basename(this.path);
    }
    get path() {
        return this.__path;
    }
    get name() {
        return path.basename(this.__path);
    }
    get ext() {
        return path.extname(this.__path).slice(1);
    }
    async contents() {
        const type = await this.type();
        if (type === 'FILE') {
            return await this.__fsImpl.readFile(this.__path);
        }
        else {
            return ''; // No contents of other types for now
        }
    }
    // TODO: Value might be able to be more than string
    async setContents(value) {
        const type = await this.type();
        if (type !== 'FILE') {
            throw new Error(`Cant write a file of type ${type}`);
        }
        return await this.__fsImpl.writeFile(this.__path, value);
    }
}
export class User extends Dataset {
    constructor(uid) {
        super();
        this.__uid = uid;
        Dataset.wrapAsyncProps(['name'], this);
    }
    get props() {
        return super.props.concat(['uid', 'name']);
    }
    get id() {
        return '' + this.__uid;
    }
    get uri() {
        return `user:${this.__uid}`;
    }
    async name() {
        // TODO FIXME: after migration
        return 'no name, fixme'; //userid.username(this.__uid);
    }
}
/**
 * A file who's source of truth is HTTP
 */
export class HTTPFile extends File {
}
// export class HTTPFile extends File {
//   static async initStorage() {
//     try {
//       await fs.mkdir(HTTPFile.storageDir);
//     }
//     catch(e) {
//       if(e.code === 'EEXIST') {
//         return; // This is okay
//       }
//       throw e; // rethrow
//     }
//   }
//   constructor(httpUrl) {
//     const name = httpUrl.slice(httpUrl.lastIndexOf('/') + 1); // TODO: Naive...
//     const fp = path.join(HTTPFile.storageDir, name);
//     super(fp);
//     this.__getFileStat = memoize(this.__getFileStat.bind(this));
//     this.__httpUrl = httpUrl;
//   }
//   /**
//    * Downloads the file from the http URL
//    */
//   async __dlFile() {
//     const resp = await fetch(this.__httpUrl);
//     // TODO: Probably throw if !resp.ok
//     const fileStream = fsSync.createWriteStream(this.path);
//     await new Promise((resolve, reject) => {
//       resp.body.pipe(fileStream);
//       resp.body.on("error", reject);
//       fileStream.on("finish", resolve);
//     });
//   }
//   /**
//    * Overwrite __getFileStat() to download the file when we need to stat it
//    */
//   async __getFileStat() {
//     await HTTPFile.initStorage();
//     await this.__dlFile();
//     return await super.__getFileStat();
//   }
// }
// // TODO, FIXME: Fix from migration to react components
// HTTPFile.storageDir = ''; //path.join(os.tmpdir(), 'dd-HTTPFile-');
