import React, { useState } from 'react';
import { CastCtxInfo, useInInspector } from '@mapcast/ui-react-core';
import FloatingContainer from './layout-primitives/FloatingContainer.jsx';
export default function Inspector(props) {
    const { castCtx } = props;
    const [inspTargetRef, setInspTargetRef] = useState(null);
    const [inspSelectionRef, setInspSelectionRef] = useState(null);
    const [inspHovered, inspSelected] = useInInspector(inspSelectionRef);
    const inspHoverStyle = {
        transform: 'translateY(-100%)',
        backgroundColor: '#000',
        pointerEvents: 'none'
    };
    const inspStyle = {
        position: "relative",
        display: "inline-block",
        backgroundColor: inspSelected || inspHovered
            ? '#666'
            : '',
        filter: inspSelected
            ? 'sepia(90%) contrast(80%) brightness(120%) hue-rotate(20deg) saturate(100%)'
            : (inspHovered
                ? 'sepia(90%) contrast(80%) brightness(120%) hue-rotate(130deg) saturate(100%)'
                : ''),
    };
    return React.createElement("span", { "data-test": "IM HERE", ref: setInspSelectionRef },
        inspSelected && React.createElement("div", { className: "inspector-container" },
            castCtx && React.createElement(CastCtxInfo, { key: castCtx.castId, onSetCastId: castCtx.setCastId, ctx: castCtx }),
            React.createElement("div", { ref: setInspTargetRef })),
        inspHovered && !inspSelected && React.createElement(FloatingContainer, { style: inspHoverStyle, className: "inspector-preview", at: inspSelectionRef }, castCtx.type.name),
        React.createElement("span", { style: inspStyle }, props.children));
}
