import React, { useState, useEffect, useMemo } from 'react';
import { Types, IntersectionType, StructuredType, LiteralType } from '@mapcast/core-type';
import { maybeUnwrap, CastToEl, useEffectBindInspectable } from '@mapcast/ui-react-core';
import { Path } from '@mapcast/core-path';
import { InspectablePromise } from 'inspectable-promise';
import Collapseable from './Collapseable.jsx';
import styles from './NodeExplorer.module.scss';
function normalizePath(path) {
    return (path.endsWith('/') || path === '') ? path.slice(0, -1) : path;
}
function nodeExplorerTypeFn(data, ctx) {
    const typeBrand = ctx.typeBrand || undefined;
    const typeMeta = ctx.typeMeta || undefined;
    const dt = Types.typeOf(data);
    if (!typeBrand && !typeMeta) {
        return dt;
    }
    const branded = {};
    if (typeBrand) {
        branded.__brand = new LiteralType(typeBrand);
    }
    if (typeMeta) {
        branded.__readOnly = new LiteralType(typeMeta.readOnly);
    }
    // Make an intersection type with the special stuff
    return new IntersectionType(dt, new StructuredType(branded));
}
export default function NodeExplorer(props) {
    const { path, resolver, ctx: _ctx, peekMap, typeHint } = props;
    const label = useMemo(() => Path.tail(path)[0], [path]);
    // Listen for changes
    const [valueChangeKey, setValueChangeKey] = useState(0);
    useEffect(() => {
        function onPathChange() {
            setValueChangeKey(prev => prev + 1);
        }
        const _path = path.endsWith('/') ? path.slice(0, -1) : path;
        resolver.listen(_path, onPathChange);
        return () => {
            resolver.unlisten(_path, onPathChange);
        };
    });
    // Find the object at self
    const [selfPeekStr, setSelfPeekStr] = useState(() => {
        if (!typeHint) {
            return '⏳';
        }
        const peekFn = peekMap.getFirstValue(undefined, typeHint);
        if (!peekFn) {
            return '⏳';
        }
        return peekFn();
    });
    const selfPromise = useMemo(() => {
        const selfResolved = resolver.get(normalizePath(path));
        return InspectablePromise.wrapPromise(Promise.resolve(selfResolved));
    }, [path, valueChangeKey]);
    useEffectBindInspectable(selfPromise);
    useEffect(() => {
        // Once the promise resolves, check the type to
        // * Provide quick view if it is that
        if (!selfPromise?.$inspect.finished) {
            return;
        }
        const value = selfPromise.$inspect.resolvedWith;
        const peekFn = peekMap.getFirstValue(value, Types.typeOf(value));
        if (!peekFn) {
            setSelfPeekStr(''); // no preview for this
            return;
        }
        setSelfPeekStr(peekFn(value));
        //setSelfPeekStr(selfPromise.$inspect.finished ? 'finished' : 'loading');
    }, [typeHint, peekMap, selfPromise.$inspect.finished]);
    const [hasChildren, setHasChildren] = useState(true);
    useEffect(() => {
        (async () => {
            const hasChildren = await resolver.hasChildren(normalizePath(path));
            setHasChildren(hasChildren);
        })();
    }, [path, valueChangeKey]);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        (async () => {
            const options = await resolver.options(path);
            setOptions(options);
        })();
    }, [path, valueChangeKey]);
    const canWrite = useMemo(() => options.includes('set'), [options]);
    // Find the children, but only after the collapseable is opened
    const [childrenPromise, setChildrenPromise] = useState();
    const [childrenQueried, setChildrenQueried] = useState(false);
    const [childrenOpen, setChildrenOpen] = useState(false);
    const [isCircular, setIsCircular] = useState(false);
    useEffect(() => {
        setChildrenQueried(false);
    }, [path, valueChangeKey]);
    useEffect(() => {
        if (!childrenOpen || childrenQueried) {
            return;
        }
        const childrenResolved = resolver.children(normalizePath(path));
        setChildrenQueried(true);
        const childrenPromise = InspectablePromise.wrapPromise(maybeUnwrap(childrenResolved));
        setChildrenPromise(childrenPromise);
        (async () => {
            const isCircular = await resolver.isCircular(normalizePath(path));
            setIsCircular(isCircular);
        })();
    }, [childrenOpen, childrenQueried]);
    //const childPath = `${path}/${c}`;
    const ctx = useMemo(() => ({
        ..._ctx,
        resolver,
        path,
        typeBrand: "@mapcast/NodeExplorer/path",
        typeMeta: {
            readOnly: !canWrite
        },
        typeFn: nodeExplorerTypeFn
    }), [_ctx, resolver, path, canWrite, valueChangeKey]);
    function onContextMenu(e) {
        if (e.ctrlKey) {
            return; // Proceed as normal
        }
        e.preventDefault();
        ctx.pushContextMenu(path, e);
    }
    function onClick(e) {
        ctx.select(path, e);
    }
    useEffect(() => {
        if (ctx?.initialSelectedPath && ctx.initialSelectedPath === path) {
            ctx.select(path);
        }
    }, []);
    const isSelected = ctx?.selectedPath === path;
    const openPath = ctx?.openPath ?? null;
    const isExternalOpen = openPath && openPath.startsWith(path);
    return React.createElement("div", { className: `node-explorer ${styles.nodeExplorer}` },
        React.createElement(Collapseable, { onChange: setChildrenOpen, canOpen: hasChildren, initialOpen: isExternalOpen },
            React.createElement("span", { className: `selectable ${isSelected ? 'selected' : ''}`, onContextMenu: onContextMenu, onClick: onClick },
                React.createElement("span", null, canWrite ? '✏️' : ''),
                React.createElement("span", null, label),
                selfPeekStr && React.createElement("span", { className: "peek-str" }, `: ${selfPeekStr}`),
                React.createElement("span", { title: "Circular Path Reference" }, isCircular ? '  🔄' : '')),
            React.createElement(CastToEl, { data: childrenPromise, ctx: ctx })));
}
