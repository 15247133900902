import { strict as assert } from 'assert';
import React, { useEffect } from 'react';
import { useInspectableFunction } from '../hooks/inspectable.js';
import CastToEl from '../CastToEl.js';
/**Unpacks a function to its last return/thrown call
 * @todo Support arguments to functions*/
export default function UnpackFunctionReturnToEl(props) {
    const { data, callOnMount, ctx } = props;
    assert(typeof data === 'function', `data must be a function`);
    const func = useInspectableFunction(data);
    const lastCall = func.$inspect.calls[func.$inspect.calls.length - 1];
    useEffect(() => {
        if (callOnMount && !lastCall) {
            func();
        }
    }, [func]);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null,
            "Calls: ",
            func.$inspect.calls.length,
            " ",
            React.createElement("button", { style: { display: 'inline-block' }, onClick: func }, "Call")),
        lastCall &&
            React.createElement(CastToEl, { data: lastCall.thrown ? lastCall.thrownWith : lastCall.returnedWith, ctx: ctx })));
}
