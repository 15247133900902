(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jsdom"));
	else if(typeof define === 'function' && define.amd)
		define(["jsdom"], factory);
	else if(typeof exports === 'object')
		exports["mcApp"] = factory(require("jsdom"));
	else
		root["mcApp"] = factory(root["jsdom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE_jsdom__) {
return 