/**
 * Extend Promise to provide synchronous inspection properties as well as
 * an external resolve and reject, and the ability to proxy another promise
 * @todo Propogate TRej through all pieces of the class
 */
export class InspectablePromise extends Promise {
    constructor(pFunc) {
        // If no func is passed, just block forever, no resolve or reject
        const _pFunc = pFunc ?? (() => { });
        // Externalize resolve and reject by wrapping pFunc
        let externalResolve, externalReject;
        // Create an object reference to refer to after super() to capture
        // the synchronous inspection parameters
        const meta = {
            finished: false,
            pending: true,
            resolved: false,
            resolvedWith: null,
            rejected: false,
            rejectedWith: null,
            onFinish: null,
            onChange: null,
            syncValueT(pendingValue, exceptionValue) {
                if (!this.finished) {
                    return pendingValue;
                }
                if (!this.resolved) {
                    // Rejected, return the exceptionValue or pendingValue if no exceptionValue
                    // was given
                    return exceptionValue || pendingValue;
                }
                return this.resolvedWith;
            },
            syncValue(pendingValue) {
                if (!this.finished) {
                    return pendingValue;
                }
                if (!this.resolved) {
                    return this.rejectedWith;
                }
                return this.resolvedWith;
            }
        };
        super((res, rej) => {
            // Wrap res and rej in something that will sync
            const newRes = (ret) => {
                meta.finished = true;
                meta.pending = false;
                meta.resolved = true;
                meta.resolvedWith = ret;
                meta.rejected = false;
                meta.rejectedWith = null;
                // TODO: Maybe we should .finally ourselves instead of putting these in the
                // reject/resolve handlers?
                if (typeof meta.onFinish === 'function') {
                    meta.onFinish();
                }
                if (typeof meta.onChange === 'function') {
                    meta.onChange();
                }
                res(ret);
            };
            const newRej = (err) => {
                meta.finished = true;
                meta.pending = false;
                meta.resolved = false;
                meta.resolvedWith = null;
                meta.rejected = true;
                meta.rejectedWith = err;
                if (typeof meta.onFinish === 'function') {
                    meta.onFinish();
                }
                if (typeof meta.onChange === 'function') {
                    meta.onChange();
                }
                rej(err);
            };
            // Externalize res and rej
            externalResolve = newRes;
            externalReject = newRej;
            // Call original pFunc
            _pFunc(newRes, newRej);
        });
        // Per the spec, the above function is executed _immediately_, which TypeScript
        // is unable to see. TypeScript infers 'externalResolve' and 'externalReject'
        // are undefined. So we cast to any to ignore it
        // https://tc39.es/ecma262/#sec-promise-executor
        this.$externalResolve = externalResolve;
        this.$externalReject = externalReject;
        // Synchonous inspection
        this.$inspect = meta;
    }
    static is(p) {
        return !!p.$inspect;
    }
    /** Wraps a Promise in a InspectablePromise */
    static wrapPromise(promise) {
        if (InspectablePromise.is(promise)) {
            return promise; // Already wrapped
        }
        const bp = new InspectablePromise();
        bp.$proxy(promise);
        return bp;
    }
    /** Wraps an async function to return an InspectablePromise instead of a Promise */
    static wrapAsyncFunc(func) {
        return function (...args) {
            return InspectablePromise.wrapPromise(func.apply(this, args));
        };
    }
    /** Wraps an async function in a function that returns a InspectablePromise only once (memoized) */
    static memoizeAsyncFunc(func) {
        const bpFunc = this.wrapAsyncFunc(func);
        const newFunc = function () {
            if (newFunc.$memo.promise) {
                return newFunc.$memo.promise;
            }
            const bp = bpFunc();
            newFunc.$memo.promise = bp;
            return bp;
        };
        newFunc.$memo = {
            promise: null,
            clear() {
                newFunc.$memo.promise = null;
            }
        };
        return newFunc;
    }
    /** Make this Promise mirror the state of the passed otherPromise */
    $proxy(otherPromise) {
        otherPromise
            .then(this.$externalResolve)
            .catch(this.$externalReject);
    }
}
