import { PropResolver, } from '@mapcast/core-data-tree';
import { FeedResolver2 } from '@mapcast/resolver-feed';
import { fetch } from './data/api/isomorphic-utils.js';
// == Data ingestion configuration ==
export const resolver = new PropResolver({
    com: {
        //bidcorp: InspectableAsyncIterator.memoizeGenFunc(BidCorpAPI.auctions),
        gsa: new FeedResolver2(fetch, `http://gsaauctions.gov/rss/MI.xml`),
        //publicSurplus: InspectableAsyncIterator.memoizeGenFunc(PublicSurplusAPI.auctions)
    }
});
import { CastMap } from '@mapcast/core-cast';
export const castMap = new CastMap();
// castMap.add(Types.forTS<IAuction>(),                            el(IAuctionEl, 'data'));
// castMap.add(Types.forRuntimeClass(Error),                       el(ErrorPrinter, 'error'));
// //castMap.set(ClassType.forCls(Dataset), DebugPrinter);
// castMap.add(Types.forRuntimeClass(Array),                       el(IAuctionsEl, 'auctions'));
// castMap.add(Types.forRuntimeClass(Promise),                     el(AwaitToEl, 'data'));
// castMap.add(isAsyncIterable,                                    el(IAuctionsEl, 'auctions'));
// castMap.add(Types.forRuntimeClass(InspectableAsyncIterator),    el(IAuctionsEl, 'auctions'));
// castMap.add(Types.forIntrinsic('any'),                          el(DebugPrinter, 'data'));
