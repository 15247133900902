import React from 'react';
import { PathMapResolver } from '@mapcast/core-data-tree';
function ColorConverterInput() {
    return React.createElement("input", null);
}
function ColorConverterPreview() {
}
export function makeColorConverter() {
    const resolver = new PathMapResolver({
        '': {},
        input: '',
        '===': null,
        hex: '',
        rgb: ''
    });
    resolver.listen('input', () => {
        const input = resolver.get('input');
        const i = input.trim();
        function hexToInt(s) {
            return Number.parseInt(s, 16);
        }
        // Get the color from input
        let match;
        let color = null;
        if (match = i.match(/^#?([0-9a-f]{3,8})$/i)) {
            const s = match[1];
            if (s.length <= 5) {
                color = {
                    r: hexToInt(s[0]),
                    g: hexToInt(s[1]),
                    b: hexToInt(s[2])
                };
            }
            else if (s.length <= 7) {
                color = {
                    r: hexToInt(s.slice(0, 2)),
                    g: hexToInt(s.slice(2, 4)),
                    b: hexToInt(s.slice(4, 6))
                };
            }
            else if (s.length === 8) {
                color = {
                    r: hexToInt(s.slice(0, 2)),
                    g: hexToInt(s.slice(2, 4)),
                    b: hexToInt(s.slice(4, 6)),
                    a: hexToInt(s.slice(6, 8))
                };
            }
        }
        else if (match = i.match(/^rgb\(\s*(\d{0,3})\s*,\s*(\d{0,3})\s*,\s*(\d{0,3})\s*(?:,\s*([\.\d]{0,3})\s*)?\)$/i)) {
            color = {
                r: Number.parseInt(match[1], 10),
                g: Number.parseInt(match[2], 10),
                b: Number.parseInt(match[3], 10),
            };
            if (match[4]) {
                color.a = Number.parseFloat(match[4]);
            }
        }
        console.log(color);
        // Set the outputs
        let hexOut = null;
        if (color) {
            hexOut = `#${color.r.toString(16)}${color.g.toString(16)}${color.b.toString(16)}`;
            if (color.a !== undefined) {
                hexOut += `${color.a.toString(16)}`;
            }
        }
        let rgbOut = null;
        if (color) {
            if (color.a !== undefined) {
                rgbOut = `rgba(${color.r.toString()},${color.g.toString()},${color.b.toString()},${color.a.toString()})`;
            }
            else {
                rgbOut = `rgb(${color.r.toString()},${color.g.toString()},${color.b.toString()})`;
            }
        }
        resolver.set('hex', hexOut);
        resolver.set('rgb', rgbOut);
    });
    return resolver;
}
