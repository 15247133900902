import { strict as assert } from 'assert';
import { useReducer, useMemo } from 'react';
import { isIterable, isAsyncIterable, Array_fromAsync } from '@mapcast/core';
import { wrapFunction } from 'inspectable-function';
import { InspectablePromise } from 'inspectable-promise';
import { InspectableIterator, InspectableAsyncIterator } from 'inspectable-iterator';
import { useEffectImmediate } from './general.jsx';
/**Binds to an Inspectable using useEffect to update the component when it changes
 * @param ins The Inspectable */
export function useEffectBindInspectable(ins) {
    assert(ins?.$inspect, 'Must pass an Inspectable');
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    // We need to _immediately_ bind to onChange otherwise we may lose events before
    // a useEffect is run
    useEffectImmediate(() => {
        ins.$inspect.onChange = () => {
            forceUpdate();
        };
        return () => {
            ins.$inspect.onChange = null;
        };
    }, [ins]);
}
export function useMemoAndBindInspectable(fn, deps) {
    const ins = useMemo(fn, deps);
    useEffectBindInspectable(ins);
    return ins;
}
// TODO: All of these are kind of a necessary evil of having to wrap every type. Ideally we could just
// let the user use useMemo and useEffectBindInspectable() inline and let them construct the useMemo fn
// but it's cumbersome to put everywhere. We will need to revisit this with shorter function names
// to make it more painless to offload the combinatoric explostion of possibilities/names here
// == InspectablePromise ==
export function useInspectablePromise(promise) {
    return useMemoAndBindInspectable(() => InspectablePromise.wrapPromise(promise), [promise]);
}
// == InspectableFunction ==
export function useInspectableFunction(func) {
    return useMemoAndBindInspectable(() => wrapFunction(func), [func]);
}
// == InspectableIterator ==
export function useInspectableIterator(itr) {
    return useMemoAndBindInspectable(() => InspectableIterator.wrapIterator(itr), [itr]);
}
export function useInspectableAsyncIterator(asyncItr) {
    return useMemoAndBindInspectable(() => InspectableAsyncIterator.wrapIterator(asyncItr), [asyncItr]);
}
/**@todo This cant be used with iterators (not iterables) because they dont have any
 * [asyncIterator] and [iterator] to determine if it's sync vs async. We could do
 * an instanceof check though...? */
export function useInspectableAnyIterable(itr) {
    assert(isIterable(itr) || isAsyncIterable(itr), `itr in useAnyIterable(itr) must have [Symbol.asyncIterator] or [Symbol.iterator] to be used with useIterator, otherwise we can't infer the type. Use useSyncIterator or useAsyncIterator if you need to use an iterator (and not an iterable) directly.`);
    // Not sure why Typescript can't get this without casts. It's not able to check the
    // concrete type (ReturnType<useAsyncIterator>) matches the conditional in UtilsItr
    // TODO: This will most likely need a useMemo and also something to prevent the branching
    // in the hooks I think too? If possible? Or maybe just force the type to always be
    // consistent?
    const iitr = (isAsyncIterable(itr)
        ? useMemoAndBindInspectable(() => InspectableAsyncIterator.wrapIterator(itr[Symbol.asyncIterator]()), [itr])
        : useMemoAndBindInspectable(() => InspectableIterator.wrapIterator(itr[Symbol.iterator]()), [itr]));
    iitr.lengthHint = itr?.length;
    return iitr;
}
/**Potentially unwrap a sync or async array-like value
 * @param value The value to unwrap, Iterators -> Arrays, Promises -> awaited value
 */
export async function maybeUnwrap(value) {
    if (Array.isArray(value)) {
        return value;
    }
    else if (value instanceof Promise) {
        return await (value);
    }
    else if (isIterable(value)) {
        return Array.from(value);
    }
    else if (isAsyncIterable(value)) {
        return await Array_fromAsync(value);
    }
    throw new Error('maybeUnwrap not valid value');
}
export function useInspectableMaybeArrayLike(maybeUnwrappable) {
    const promiseToArray = useMemo(() => maybeUnwrap(maybeUnwrappable), [maybeUnwrappable]);
    return useInspectablePromise(promiseToArray);
}
