import jsdom from 'jsdom';
// TODO: https://github.com/lquixada/cross-fetch/issues/121
//import _fetch from 'cross-fetch';

const isBrowser = typeof window !== 'undefined';

// DOMParser.parseFromString
export let parseDOMFromString;
if (isBrowser) {
  parseDOMFromString = (text) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc;
  };
}
else {
  const { JSDOM } = jsdom;
  parseDOMFromString = (text) => {
    const dom = new JSDOM(text);
    const doc = dom.window.document;
    return doc;
  };
}

// fetch
async function fetch(originalUrl, ...args) {
  // const urlSplit = originalUrl.split('://');
  // let scheme, url;
  // if (urlSplit.length > 1) {
  //   scheme = urlSplit[0];
  //   url = urlSplit[1];
  // }
  // else {
  //   scheme = undefined;
  //   url = urlSplit[0];
  // }

  // const proxiedUrl = `${scheme ? `${scheme}://` : ''}localhost:7766/${url}`;
  const proxiedUrl = `http://localhost:7766/${originalUrl}`;
  return await window.fetch(proxiedUrl, ...args);
}
export { fetch };