import { InspectablePromise } from 'inspectable-promise';
import { InspectableAsyncIterator } from 'inspectable-iterator';
/**
 * When thinking about what should be a dataset, don't think purely in objects,
 * think about the tuple of (Representation, Object). Anything that makes it unique
 * or polymorphic might spawn a new type of dataset, inheriting/implementing the interfaces
 * that make sense
 */
export class Dataset {
    constructor() {
        // TODO: Make this a little friendly to use
        this.__instanceId = '' + Math.random();
    }
    get _isIDataset() { return true; }
    static isIDataset(obj) {
        if (typeof obj !== 'object') {
            return false;
        }
        return obj._isIDataset;
    }
    static wrapAsyncProps(props, obj) {
        for (const prop of props) {
            obj[prop] = InspectablePromise.memoizeAsyncFunc(obj[prop].bind(obj));
        }
    }
    static wrapAsyncGenProps(props, obj) {
        for (const prop of props) {
            obj[prop] = InspectableAsyncIterator.memoizeGenFunc(obj[prop].bind(obj));
        }
    }
    static new(...args) {
        // Create a new instance
        // TODO: refactor this out in the future
        // @ts-ignore
        return new this(...args);
    }
    get props() {
        return ['id', 'instanceId'];
    }
    get instanceId() {
        return this.__instanceId;
    }
    /**
     * @prop {String} id A unique id that represents this object
     */
    get id() {
        throw new Error('Dataset#id Not implemented in base class');
    }
    equals(other) {
        return Dataset.isIDataset(other) &&
            this.id === other.id;
    }
    /**
     * Converts the Dataset to a synchronous representation of itself, all resolved
     * promises and iterators and such are resolved to current state
     * @param {function} cb A callback that will be called with any in-progress promises
     * while generating the toSync object. You will need to handle these otherwise
     * you might get unhandled rejection errors
     */
    toSync(cb) {
        const syncObj = {};
        for (const prop of this.props) {
            const propVal = this[prop];
            const isPropFunc = typeof propVal === 'function' && propVal?.$memo;
            if (!isPropFunc) {
                // propVal is not an inspectable function, try toSync
                // otherwise store as-is
                syncObj[prop] = typeof propVal?.toSync === 'function'
                    ? propVal.toSync(cb)
                    : syncObj[prop] = propVal;
            }
            else {
                // propVal is an async function w/ memoization
                syncObj[prop] = (!propVal.$memo.promise // Does the memo promise even exist yet
                    ? 'not retrieved'
                    : (!propVal.$memo.promise.$inspect.finished // Is the promise finished loading yet
                        ? 'loading'
                        : (propVal.$memo.promise.$inspect.rejected
                            ? `${propVal.$memo.promise.$inspect.rejectedWith.message}`
                            : (typeof propVal.$memo.promise.$inspect.resolvedWith?.toSync === 'function'
                                ? propVal.$memo.promise.$inspect.resolvedWith.toSync(cb)
                                : propVal.$memo.promise.$inspect.resolvedWith))));
                // TODO: In the future, maybe we'd want to be able to do toSync() and only bind to the
                // promises that are currently in flight, and not trigger new ones
                // Listen for callbacks on unfinished promises
                if (typeof cb === 'function' &&
                    !propVal.$memo.promise) {
                    // No promise yet, access it and bind to the return
                    const propPromise = propVal();
                    cb(propPromise);
                }
            }
        }
        return syncObj;
    }
}
/** Some basic interfaces
 */
export class IEvent {
}
//export class Event extends Dataset, IEvent {}
/**
 * Dataset that describes an event that can be ordered with respect to other items
 */
// export class IsOrdered extends Dataset {
//   /**
//    * Asynchronous generator that returns a list of the direct previous Datasets
//    * @returns {Event[]} previous
//    */
//   async previous() {
//     throw new Error('not implemented');
//   }
//   /**
//    * Asynchronous generator that returns a list of the direct next Datasets
//    * @returns {Event[]} next
//    */
//   async next() {
//     throw new Error('not implemented');
//   }
// }
// /**
//  * Dataset that is on a map (lat/lng)
//  */
// export class IsGeoLocation extends Dataset {
//   /**
//    * @returns {Number} lat
//    */
//   async lat() {
//     throw new Error('not implemented');
//   }
//   /**
//    * @returns {Number} lng
//    */
//   async lng() {
//     throw new Error('not implemented');
//   }
// }
/**
 * Navigation event
 {
   "location": "http:com/example" # Where the navigation went
   "time": 1632800032967
 }
 */
// export class Navigation {
//   constructor(uri) {
//     super();
//     this.location = uri;
//     this.time = Date.now();
//   }
// }
