import SafeDebugPrinter from './SafeDebugPrinter.jsx';
export { default as CodeMirror } from './CodeMirror.jsx';
export { SafeDebugPrinter };
/**deprecated, remove eventually*/
export const DebugPrinter = SafeDebugPrinter;
export { default as ErrorBoundary } from './ErrorBoundary.jsx';
export { default as ErrorPrinter } from './ErrorPrinter.jsx';
export { default as CastToEl, CastToElContext } from './CastToEl.jsx';
export { default as CastCtxInfo } from './CastCtxInfo.jsx';
export { default as AwaitToEl } from './cast-utils/AwaitToEl.jsx';
export { default as UnpackIterableToEls } from './cast-utils/UnpackIterableToEls.jsx';
export { default as UnpackFunctionReturnToEl } from './cast-utils/UnpackFunctionReturnToEl.jsx';
export { default as UnpackModuleMainToEl } from './cast-utils/UnpackModuleMainToEl.jsx';
export * from './hooks/general.js';
export * from './hooks/inspectable.js';
export * from './hooks/inspector.js';
/**Wrapper around React.createElement that
 * 1. Returns a function with a custom name matching the component (for castMap to pick up)
 * 2. Maps the one parameter the function takes to the named value in the props
 */
import React from 'react';
export function el(component, propFn) {
    const componentName = component.name;
    const fn = { [componentName]: function (...args) {
            const props = propFn(...args);
            return React.createElement(component, props);
        } }[componentName];
    return fn;
}
