import React, { useMemo, useState, useEffect } from 'react';
import CastToEl from '../CastToEl.jsx';
import { InspectablePromise } from 'inspectable-promise';
import { useEffectBindInspectable } from '../hooks/inspectable.js';
import styles from './AwaitToEl.module.scss';
/**Awaits a Promise and casts with CastToEl, showing a loading spinner while waiting
 * @todo It should be an option to do the "keep-alive" functionality by caching
 * CastToEl when a new promise comes in
 **/
export default function AwaitToEl(props) {
    const { data, ctx } = props;
    const iPromise = useMemo(() => InspectablePromise.wrapPromise(data), [data]);
    useEffectBindInspectable(iPromise);
    // We want to _actually_ render
    const [dataReady, setDataReady] = useState(false);
    const [dataDisplay, setDataDisplay] = useState(null);
    useEffect(() => {
        if (!iPromise.$inspect.finished) {
            // If it's not finished, don't do anything, but DONT reset dataReady, we want
            // it to be ready after the first promise resolves, so we never have to show
            // a loader and tear down the DOM tree again. This prevents us from losing
            // state on inputs and other critical things when the promise changes to
            // a new value
            return;
        }
        setDataDisplay(iPromise.$inspect.rejectedWith || iPromise.$inspect.resolvedWith);
        setDataReady(true);
    }, [iPromise, iPromise.$inspect.finished]);
    return dataReady
        ? React.createElement(CastToEl, { data: dataDisplay, ctx: ctx })
        : React.createElement("span", { className: styles.spinner });
}
