import React, { useState, useMemo, useContext } from 'react';
import SafeDebugPrinter from './SafeDebugPrinter.jsx';
import ErrorBoundary from './ErrorBoundary.jsx';
import ErrorPrinter from './ErrorPrinter.jsx';
import { CastMap, FnCaster } from '@mapcast/core-cast';
;
const CastToElContext = React.createContext(new CastMap()); // empty map for castMap
export { CastToElContext };
export default function CastToEl(props) {
    const { data, typeFn: _typeFn, ctx } = props;
    const typeFn = _typeFn || ctx?.typeFn;
    const id = useMemo(() => '' + Math.random(), []); // TODO: Better id
    const [internalCastId, setInternalCastId] = useState(null);
    const castId = internalCastId; // ?? _castId;
    // castMap specified in a context to choose how to map types to elements
    const castMap = useContext(CastToElContext);
    const caster = useMemo(() => {
        return new FnCaster(castMap);
    }, [castMap]);
    // Create the ctx that will go to the caster using useMemo so we don't get a new
    // object reference each time
    const ctxToCaster = useMemo(() => ({
        ...ctx,
        typeFn,
        setCastId: setInternalCastId,
        castToElId: id
    }), [ctx, typeFn]);
    const [outputError, setOutputError] = useState(undefined);
    const [outputKey, setOutputKey] = useState(Math.random());
    //useDebugDeps({data, castMap, ctx, typeFn});
    const castOutput = useMemo(() => {
        if (!!outputError) {
            setOutputError(undefined);
            setOutputKey(Math.random()); // Cycle key
        }
        const dataType = typeFn ? typeFn(data, ctxToCaster) : undefined;
        const defaultCast = (data) => {
            return data instanceof Error
                ? React.createElement(ErrorPrinter, { data: data })
                : React.createElement(SafeDebugPrinter, { data: data });
        };
        try {
            const value = caster.cast(data, dataType, ctxToCaster, castId, defaultCast);
            return !React.isValidElement(value)
                ? React.createElement("p", null, "Cast ended, value was not valid React element to mount")
                : value;
        }
        catch (e) {
            // Ouch, cast and the recast of error failed... we need to output this error
            return React.createElement(ErrorPrinter, { error: e });
        }
    }, [data, ctxToCaster, caster, typeFn, castId]);
    const onOutputError = (error, errorInfo) => {
        setOutputError(error);
    };
    return React.createElement(ErrorBoundary, { onError: onOutputError, key: outputKey }, castOutput);
}
