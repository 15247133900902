import { strict as assert } from 'assert';
import { Types } from '@mapcast/core-type';
import { IResolver, NotFound } from './IResolver.js';
export function pathObjectToPathMap(obj, _currPath, _depth) {
    if (_depth && _depth >= 100) {
        console.error(`pathObjectToPathMap depth too deep. Possible circular reference detected. You can't use this with object with circular references`);
        console.error(`TODO: This would be better if we kept a stack of seen parents and detect it that way`);
        return {};
    }
    const depth = _depth ?? 0;
    const pathPrefix = _currPath ? _currPath + '/' : '';
    // Iterate over all entries in obj and collect their paths into pathMap. Recurse
    // if they are an object
    let pathMap = {};
    Object.entries(obj)
        .forEach(([k, v]) => {
        const path = `${pathPrefix}${k}`;
        if (typeof v === 'object' && v !== null) {
            const childPaths = pathObjectToPathMap(v, path, depth + 1);
            pathMap = {
                ...pathMap,
                ...childPaths
            };
        }
        else {
            pathMap[path] = v;
        }
    });
    return pathMap;
}
/**A Resolver that maps path entries to output objects, forwards requests onto
 * nested Resolvers that exist in the path map object
 */
export class PathMapResolver {
    constructor(pathMap, opts) {
        this.isIResolver = true;
        assert(typeof pathMap === 'object', `pathMap in PathMapResolver must be an object, got '${typeof pathMap}'`);
        this.__readOnly = !!(opts?.readOnly);
        this.__pathValueMap = {};
        this.__pathIResolverMap = {};
        this.__listenMap = {};
        Object.entries(pathMap).forEach(([k, v]) => this._addPath(k, v));
    }
    static fromObject(obj, opts) {
        const pathMap = pathObjectToPathMap(obj);
        return new PathMapResolver(pathMap, opts);
    }
    get _paths() {
        return Object.keys(this.__pathValueMap)
            .concat(Object.keys(this.__pathIResolverMap));
    }
    /**Adds a path*/
    _addPath(path, value) {
        assert(!path.endsWith('/'), `Path to add "${path}" must not end with a /`);
        if (IResolver.is(value)) {
            this.__pathIResolverMap[path] = value;
        }
        else {
            this.__pathValueMap[path] = value;
        }
    }
    /**Removes a path*/
    _removePath(path) {
        assert(!path.endsWith('/'), `Path to add "${path}" must not end with a /`);
        delete this.__pathValueMap[path];
        // TODO: Not sure if this works for the below, need to think it through
        delete this.__pathIResolverMap[path];
    }
    /**Returns the object at the requested path, the IResolver + rest path, or NotFound*/
    _getPath(path) {
        assert(!path.endsWith('/'), `path must not end with a /`);
        // Try first in the __pathValueMap
        if (path in this.__pathValueMap) {
            return {
                object: this.__pathValueMap[path]
            };
        }
        // Try next in __pathIResolverMap for resolvers prefixed with the current path
        const ret = Object.entries(this.__pathIResolverMap)
            .find(([rPath, resolver]) => path.startsWith(rPath + '/') || path === rPath);
        if (ret) {
            const [rPath, resolver] = ret;
            return {
                object: resolver,
                restPath: path.slice(rPath.length + 1)
            };
        }
        // nothing found
        return {
            object: NotFound
        };
    }
    /**Returns all the children at the requested path, but only works if the path
     * is actually inside us and not inside another nested IResolver (caller should
     * check this)*/
    _getPathChildren(path) {
        assert(!path.endsWith('/'), `path must not end with a /`);
        if (path === '') {
            // Handle root path separately
            return this._paths
                .filter(p => !p.slice(path.length).includes('/') && p !== '');
        }
        const _path = path + '/';
        return this._paths
            .filter(p => p.startsWith(_path) && !p.slice(_path.length).includes('/'))
            // Map to the name
            .map(p => p.slice(_path.length));
    }
    children(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.children(restPath);
        }
        if (object === NotFound) {
            return [];
        }
        const children = this._getPathChildren(path);
        return children.map(c => ({
            name: c
        }));
    }
    hasChildren(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.hasChildren(restPath);
        }
        return this._getPathChildren(path).length > 0;
    }
    typeHint(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.typeHint(restPath);
        }
        if (object === NotFound) {
            return Types.forLiteral(NotFound);
        }
        return Types.typeOf(object);
    }
    isCircular(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.isCircular(restPath);
        }
        return false;
    }
    // == verbs ==
    options(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.options(restPath);
        }
        return this.__readOnly
            ? ['get', 'listen', 'unlisten']
            : ['get', 'set', 'listen', 'unlisten'];
    }
    get(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.get(restPath);
        }
        return object;
    }
    set(path, value) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.set(restPath, value);
        }
        if (this.__readOnly) {
            throw new Error('PathMapResolver is readOnly');
        }
        this._addPath(path, value);
        // notify listeners
        if (this.__listenMap[path]) {
            // TODO: Make this happen asynchronously?
            this.__listenMap[path].forEach(cb => cb());
        }
    }
    add(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.add(restPath);
        }
        if (this.__listenMap[path]) {
            // TODO: Make this happen asynchronously?
            this.__listenMap[path].forEach(cb => cb());
        }
        throw new Error(`add() not implemented at path ${path}`);
    }
    delete(path) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.delete(restPath);
        }
        if (this.__listenMap[path]) {
            // TODO: Make this happen asynchronously?
            this.__listenMap[path].forEach(cb => cb());
        }
        throw new Error(`delete() not implemented at path ${path}`);
    }
    listen(path, cb) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.listen(restPath, cb);
        }
        assert(!path.endsWith('/'), `Path to listen to "${path}" must not end with a /`);
        if (!this.__listenMap[path]) {
            this.__listenMap[path] = [];
        }
        assert(!this.__listenMap[path].includes(cb), `cb is already listening at "${path}" and should not be added a second time`);
        this.__listenMap[path].push(cb);
    }
    unlisten(path, cb) {
        const { object, restPath } = this._getPath(path);
        if (IResolver.is(object)) {
            return object.unlisten(restPath, cb);
        }
        assert(!path.endsWith('/'), `Path to unlisten to "${path}" must not end with a /`);
        if (!this.__listenMap[path]) {
            return;
        }
        this.__listenMap[path] = this.__listenMap[path].filter(fn => fn !== cb);
    }
}
