import { IntrinsicType } from '@mapcast/core-type';
// TODO: Convert to Symbols, Typescript doesnt handle them well though
// Special values definining certain IResolver returns
/**Represents a path that exists but containing no value (TODO: not even undefined, perhaps
 * we should just used undefined?)*/
export const Empty = '@mapcast/resolver/Empty'; //Symbol.for('@mapcast/resolver/Empty');
/**Represents an invalid path*/
export const NotFound = '@mapcast/resolver/NotFound'; //Symbol.for('@mapcast/resolver/NotFound');
export const KNOWN_OPTIONS = [
    'add',
    'get',
    'set',
    'options',
    'delete',
    'listen',
    'unlisten'
];
export const KNOWN_PROPS = [
    ...KNOWN_OPTIONS,
    'children',
    'hasChildren',
    'typeHint',
    'isCircular'
];
export class IResolver {
    constructor() {
        this.isIResolver = true;
        // async *paths(rootPath: string, seenIds: string[] = []): AsyncGenerator<string> {
        //   let childrenAtPath;
        //   try {
        //     childrenAtPath = (await this.children(rootPath)) as unknown as string[];
        //   }
        //   catch(e) {
        //     console.error(`Encountered error when getting children at ${rootPath}`)
        //     console.error(e);
        //     return;
        //   }
        //   if ((childrenAtPath as any).circular) {
        //     return; // No paths here, it was a circular reference
        //   }
        //   for await (const child of childrenAtPath) {
        //     const childPath = `${rootPath}/${child}`;
        //     let seenIdsPass = seenIds.slice();
        //     try {
        //       const r = await this.resolve(childPath) as any;
        //       console.log('GOT r', r);
        //       if (seenIds.includes(r.id)) {
        //         continue; // Skip this one, we've already seen this object
        //       }
        //       seenIdsPass = [...seenIds, r.id];
        //     }
        //     catch(e){
        //       console.error(`Encountered error when getting children at ${rootPath}`)
        //       console.error(e);
        //     }
        //     yield childPath;
        //     yield* this.paths(childPath, seenIdsPass);
        //   }
        // }
        // async *walk(rootPath: string): AsyncGenerator<INode<T> | Promise<INode<T>>> {
        //   const pathItr = this.paths(rootPath);
        //   for await (const p of pathItr) {
        //     yield this.resolve(p); // Ignore the string[], we're not calling ',children'
        //   }
        // }
    }
    static is(o) {
        return !!o?.isIResolver;
    }
    // == props ==
    children(path) { throw new Error('not implemented'); }
    hasChildren(path) { throw new Error('not implemented'); }
    typeHint(path) { throw new Error('not implemented'); }
    isCircular(path) { throw new Error('not implemented'); }
    // == verbs ==
    /**Returns an INode at the given path
     * @param path The path to return the object for. '' is root, path's should
     * not start with '/'
     * @returns Any value. Might be a Promise (if resolve() is asynchronous, which
     * a lot of them are) and also might be an Iterator or AsyncIterator (if resolve()
     * returns an extreme amount of values, to give you a small view into the dataset
     * without querying the entire thing)
     */
    // resolve(path: string, props: string[]): MaybePromise<INode<T>> {
    //   throw new Error('not implemented');
    // }
    get(path) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
    set(path, value) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
    options(path) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
    add(path) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
    delete(path) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
    listen(path, cb) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
    unlisten(path, cb) {
        const name = Object.getPrototypeOf(this)?.constructor?.name;
        throw new Error(`not implemented for "${name}"`);
    }
}
export class BaseResolver {
    constructor() {
        this.isIResolver = true;
    }
    children() {
        return [];
    }
    hasChildren() {
        return false;
    }
    typeHint() {
        return new IntrinsicType('any');
    }
    isCircular() {
        return false;
    }
    set() {
        throw new Error('add not implemented on this IResolver');
    }
    add() {
        throw new Error('add not implemented on this IResolver');
    }
    delete() {
        throw new Error('delete not implemented on this IResolver');
    }
    listen() {
        throw new Error('listen not implemented on this IResolver');
    }
    unlisten() {
        throw new Error('unlisten not implemented on this IResolver');
    }
}
