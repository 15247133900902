import { useState, useEffect } from 'react';
const INSPECTOR_CLASS_NAME = "INSPECTOR-MARKER";
let hovered = undefined;
const hoveredListeners = [];
const hoveredSet = (a) => {
    hovered = a;
    hoveredListeners.forEach((f) => f(a));
};
let selected = undefined;
const selectedListeners = [];
const selectedSet = (a) => {
    selected = a;
    selectedListeners.forEach((f) => f(a));
};
let alt = false;
const altListeners = [];
const altSet = (a) => {
    alt = a;
    altListeners.forEach((f) => f(a));
};
window.addEventListener('keydown', (e) => {
    if (e.key === 'Alt') {
        e.preventDefault();
        altSet(true);
    }
});
window.addEventListener('keyup', (e) => {
    if (e.key === 'Alt') {
        e.preventDefault();
        altSet(false);
    }
});
function findMarked(el) {
    // Travel through the stack and find the closest highlight castmap matcher
    let currEl = el;
    while (currEl.parentElement) {
        if (currEl.classList.contains(INSPECTOR_CLASS_NAME)) {
            break;
        }
        currEl = currEl.parentElement;
    }
    return currEl;
}
window.addEventListener('mousemove', (e) => {
    if (!alt) {
        return;
    }
    const el = document.elementFromPoint(e.clientX, e.clientY);
    hoveredSet(findMarked(el));
});
window.addEventListener('click', (e) => {
    if (!alt) {
        return;
    }
    const el = document.elementFromPoint(e.clientX, e.clientY);
    selectedSet(findMarked(el));
});
/**Given an interactEl, will participate in inspector picking. If the element is
 * hovered over on the screen in inspector mode, it will become "hovered". If the
 * element is clicked on, it will become "selected". This handles the global "hovered"
 * and "selected"
 * @returns An array of ["hovered", "selected"] as described above
 */
export function useInInspector(interactEl) {
    const [alt, setAlt] = useState(false);
    const [hover, setHover] = useState(false);
    const [selected, setSelected] = useState(false);
    useEffect(() => {
        if (!interactEl) {
            return;
        }
        function onAltChange(a) {
            setAlt(a);
            setHover(false);
            setSelected(false);
        }
        function onHoverChange(el) {
            setHover(el === interactEl);
        }
        function onSelectedChange(el) {
            setSelected(el === interactEl);
        }
        altListeners.push(onAltChange);
        hoveredListeners.push(onHoverChange);
        selectedListeners.push(onSelectedChange);
        interactEl.classList.add(INSPECTOR_CLASS_NAME);
        return () => {
            const hoveredIdx = hoveredListeners.indexOf(onHoverChange);
            if (hoveredIdx > -1) {
                hoveredListeners.splice(hoveredIdx, 1);
            }
            const selectedIdx = selectedListeners.indexOf(onSelectedChange);
            if (selectedIdx > -1) {
                selectedListeners.splice(selectedIdx, 1);
            }
            const altIdx = altListeners.indexOf(altListeners);
            if (altIdx > -1) {
                altListeners.splice(altIdx, 1);
            }
            interactEl.classList.remove(INSPECTOR_CLASS_NAME);
        };
    });
    return [alt && hover, alt && selected];
}
export function inspectorClassName(hovered, selected) {
    return selected
        ? 'inspector-selected'
        : hovered
            ? 'inspector-hovered'
            : '';
}
