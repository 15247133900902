import React, { useState, useEffect } from 'react';
import * as ReactDOM from "react-dom";
import { useInspectableAnyIterable } from '../hooks/inspectable.js';
import CastToEl from '../CastToEl.jsx';
import styles from './UnpackIterableToEls.module.scss';
/**Unpacks Iterables and similar into multiple CastToEl components
 */
export default React.forwardRef(function UnpackIterableToEl(props, selfRef) {
    const { data, ctx, unpackOnMount: _unpackOnMount, inspectorEl } = props;
    const itr = useInspectableAnyIterable(data);
    const unpackOnMount = _unpackOnMount || 0;
    useEffect(() => {
        if (unpackOnMount === 0) {
            return;
        }
        itr.take(unpackOnMount);
    }, [data]);
    const [format, setFormat] = useState("column");
    function toggleFormat() {
        if (format === 'column') {
            setFormat('row');
        }
        else {
            setFormat('column');
        }
    }
    return React.createElement("span", null,
        inspectorEl && ReactDOM.createPortal((React.createElement("span", { className: "info" },
            "Length: (",
            itr.$inspect.values.length || 0,
            "/",
            itr.lengthHint ?? '??',
            itr.$inspect.done ? 'F' : '',
            ")",
            React.createElement("button", { style: { display: 'inline-block' }, onClick: itr.take.bind(itr, 10) }, "More"),
            React.createElement("button", { onClick: toggleFormat }, format === 'row' ? 'Row' : 'Column'))), inspectorEl),
        React.createElement("div", { className: `${styles.uite} ${format === 'column' ? 'format-column' : ''}` }, itr.$inspect.values.length === 0
            ? React.createElement("div", { className: "empty" })
            : itr.$inspect.values.map((d, idx) => (React.createElement("div", { key: idx, className: "element" },
                React.createElement(CastToEl, { data: d, ctx: ctx }))))));
});
