import { strict as assert } from 'assert';
import { Path } from '@mapcast/core-path';
import { Types } from '@mapcast/core-type';
import { IResolver, NotFound } from './IResolver.js';
function Object_keysAll(o) {
    const strNames = Object.getOwnPropertyNames(o)
        // "hidden" properties
        .filter(s => !s.startsWith('__') && !(s.startsWith('$') && !s.startsWith('$$')));
    //const symNames = []; //Object.getOwnPropertySymbols(o);
    return strNames;
    //return (strNames as (string | symbol)[])
    //  .concat(symNames);
}
function isIndexable(o) {
    return o !== null && (typeof o === 'object' || typeof o === 'function');
}
/**Uses a JSON object to organize other resolvers, resolves to link objects at intermediate
 * nodes
 */
export class PropResolver {
    constructor(pathObj) {
        this.isIResolver = true;
        this.__pathObj = pathObj;
        this.__listenMap = {};
    }
    /**Create a flat object where all the nested properties*/
    // _objToPaths(obj: { [k: string]: unknown }, currPath: string) {
    //   let pathMap = {};
    //   let seenObjs = [];
    //   for (const k of Object_keysAll(obj)) {
    //     const p = Path.join(`${currPath}/${k}`;
    //     pathMap = {
    //       ...pathMap,
    //       ...this._objsToPaths(obj, p),
    //       [p]: obj[k]
    //     };
    //   }
    //   return pathMap;
    // }
    /**Traverses to the given path in the pathObj and returns
     * .object The object at the path
     * .parent The parent of the object
     * .parentProp The prop name that .object exist on .parent
     * .restPath The rest of the path if we hit another IResolver
     * .circular If there was a circular reference
     */
    _traversePath(path) {
        assert(typeof path === 'string', 'path must be a string');
        let currObj = this.__pathObj;
        let lastObj = {};
        const seenObjs = [];
        let hasCircularReference = false;
        let lastName = '';
        let restPath = undefined; // Normally, there is no more path, unless we hit a nested resolver
        const partItr = Path.itr(path);
        for (const _part of partItr) {
            const part = _part;
            if (part.value === '') {
                // Skip empty names
                continue;
            }
            if (!isIndexable(currObj)) {
                // The object is not traversable any farther
                // TODO: This should never be called before we do at least 1 iteration, but
                // this is only enforced through typing in the constructor of _pathObj
                currObj = NotFound;
                break;
            }
            // currObj is indexable and we can traverse another property on it
            lastName = part.value;
            lastObj = currObj;
            seenObjs.push(currObj);
            currObj = currObj[part.value]; // Traverse the property
            hasCircularReference || (hasCircularReference = seenObjs.includes(currObj));
            if (IResolver.is(currObj)) {
                // Send just the params + rest of path to the next resolver
                const shift = part.string.startsWith('/');
                const restParams = part.string.slice(part.value.length + (shift ? 1 : 0));
                restPath = `${restParams}${restParams !== '' && partItr.rest() !== '' ? '/' : ''}${partItr.rest()}`;
                break;
            }
        }
        return {
            object: currObj,
            parent: lastObj,
            parentProp: lastName,
            restPath,
            circular: hasCircularReference
        };
    }
    /**Returns the resolver responsible for a given path*/
    _resolverForPath(path) {
        const { object, restPath } = this._traversePath(path);
        return (IResolver.is(object))
            ? object
            : this;
    }
    children(path) {
        const { object, restPath } = this._traversePath(path);
        if (object === NotFound) {
            return [];
        }
        if (IResolver.is(object)) {
            return object.children(restPath);
        }
        if (!isIndexable(object)) {
            return [];
        }
        return Object_keysAll(object)
            .map(c => ({
            name: c
        }));
    }
    hasChildren(path) {
        const { object, restPath } = this._traversePath(path);
        if (object === NotFound) {
            return false;
        }
        if (IResolver.is(object)) {
            return object.hasChildren(restPath);
        }
        if (!isIndexable(object)) {
            return false;
        }
        return Object_keysAll(object).length > 0;
    }
    typeHint(path) {
        const { object, restPath } = this._traversePath(path);
        if (object === NotFound) {
            return Types.forLiteral('@mapcast/resolver/NotFound');
        }
        if (IResolver.is(object)) {
            return object.typeHint(restPath);
        }
        return Types.typeOf(object);
    }
    isCircular(path) {
        const { object, restPath, circular } = this._traversePath(path);
        if (IResolver.is(object)) {
            return object.isCircular(restPath);
        }
        return circular;
    }
    // == verbs ==
    options(path) {
        const resolver = this._resolverForPath(path);
        if (resolver === this) {
            return ['get'];
        }
        return resolver.options(path);
    }
    get(path) {
        const { object, restPath } = this._traversePath(path);
        if (object === NotFound) {
            return '@mapcast/resolver/NotFound';
        }
        if (IResolver.is(object)) {
            // TODO: This needs better typing to enforce that sub resolvers are of
            // the correct typing for ourself
            return object.get(restPath);
        }
        return object;
    }
    set(path, value) {
        const { object, parent, parentProp, restPath } = this._traversePath(path);
        if (IResolver.is(object)) {
            object.set(restPath, value);
            return;
        }
        parent[parentProp] = value;
        // notify listeners
        if (this.__listenMap[path]) {
            // TODO: Make this happen asynchronously?
            this.__listenMap[path].forEach(cb => cb());
        }
    }
    add(path) {
        const { object, restPath } = this._traversePath(path);
        if (!(IResolver.is(object))) {
            throw new Error(`add() not implemented at path ${path}`);
        }
        return object.add(restPath);
    }
    delete(path) {
        const { object, restPath } = this._traversePath(path);
        if (!(IResolver.is(object))) {
            throw new Error(`delete() not implemented at path ${path}`);
        }
        return object.delete(restPath);
    }
    listen(path, cb) {
        const { object, restPath } = this._traversePath(path);
        if (IResolver.is(object)) {
            return object.listen(restPath, cb);
        }
        assert(!path.endsWith('/'), `Path to listen to "${path}" must not end with a /`);
        if (!this.__listenMap[path]) {
            this.__listenMap[path] = [];
        }
        assert(!this.__listenMap[path].includes(cb), `cb is already listening at "${path}" and should not be added a second time`);
        this.__listenMap[path].push(cb);
    }
    unlisten(path, cb) {
        const { object, restPath } = this._traversePath(path);
        if (IResolver.is(object)) {
            return object.unlisten(restPath, cb);
        }
        assert(!path.endsWith('/'), `Path to unlisten to "${path}" must not end with a /`);
        if (!this.__listenMap[path]) {
            return;
        }
        this.__listenMap[path] = this.__listenMap[path].filter(fn => fn !== cb);
    }
}
