import React, { useEffect } from 'react';
import FloatingContainer from '../layout-primitives/FloatingContainer.jsx';
export default function ContextMenu(props) {
    const { at, onClose, children } = props;
    useEffect(() => {
        function handleClick() {
            onClose();
        }
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    });
    return React.createElement(FloatingContainer, { at: at }, children);
}
