import React, { useState, useEffect, useRef } from 'react';
import { Path } from '@mapcast/core-path';
export default function NodeExplorerPathInput(props) {
    const { path, onPath } = props;
    // Normalize the path to not have a '/'
    const parts = Array.from(Path.itr(path));
    const textEditRef = useRef();
    const [editingAsText, setEditingAsText] = useState(false);
    function editAsText() {
        setEditingAsText(true);
    }
    useEffect(() => {
        if (editingAsText && textEditRef.current) {
            textEditRef.current.focus();
        }
    }, [editingAsText]);
    return React.createElement("span", null, editingAsText
        ? React.createElement("input", { ref: textEditRef, value: path, onChange: (e) => onPath(e.target.value.trim()), onBlur: () => setEditingAsText(false) })
        : React.createElement(React.Fragment, null,
            parts.map((p, idx, arr) => {
                const pathAtIdx = Path.join(arr.slice(0, idx + 1));
                return React.createElement("button", { key: p.string + idx, style: { display: "inline-block" }, onClick: () => onPath(pathAtIdx) }, p.value);
            }),
            React.createElement("button", { onClick: () => setEditingAsText(true) }, "\u00A0\u00A0\u00A0\u00A0")));
}
