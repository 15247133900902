function pathPartInfo(lastSep, str) {
    return {
        string: `${lastSep ?? ''}${str}`,
        value: str,
        // The first part in a chunk
        //first: lastSep === '/' || lastSep === undefined
    };
}
/**Iterates over the parts of path, splitting the path on '/' and ',', yielding
 * PathParts, which give you .value, the name between the separators, and .string
 * which is .value + the original separator prefix (if any)
 */
export class PathPartIterator {
    constructor(pathStr) {
        this._pathStr = pathStr;
        this._stringAcc = '';
        this._lastSepChr = undefined;
        this._lastIdx = 0;
        this._done = false;
        this._peekRet = undefined;
    }
    next() {
        if (this._done || this._pathStr === '') {
            // Handles final done call + empty string
            return {
                value: undefined,
                done: true
            };
        }
        this._peekRet = undefined; //Clear any previous peek
        const str = this._pathStr;
        for (let i = this._lastIdx; i < str.length; i++) {
            const chr = str[i];
            // Handle a seperator
            if (chr === ',' || chr === '/') {
                if (this._stringAcc === '') {
                    // If starts with a '/' or ',', don't emit an empty string as it's not
                    // useful to anyone iterating over a path. This has the side effect of
                    // also producing one path part for the root path '/' with a .value of
                    // empty string
                    this._lastSepChr = chr;
                    continue;
                }
                const ret = pathPartInfo(this._lastSepChr, this._stringAcc);
                this._stringAcc = ''; // Clear current accumulation in preparation for yield
                //if (chr === '/') {
                //  ret.last = true; // Last of the path chunk
                //}
                this._lastSepChr = chr;
                this._lastIdx = i + 1;
                return {
                    value: ret,
                    done: false
                };
            }
            else {
                this._stringAcc += chr;
            }
        }
        // Yield last part if
        const ret = pathPartInfo(this._lastSepChr, this._stringAcc);
        this._stringAcc = ''; // Clear current accumulation
        //ret.last = true; // Always last: true for this
        this._lastIdx = str.length;
        this._done = true;
        return {
            value: ret,
            done: false
        };
    }
    /**Returns the next value without iterating over it
     */
    peek() {
        if (this._peekRet) {
            return this._peekRet;
        }
        // Save state
        const stringAcc = this._stringAcc;
        const lastSepChr = this._lastSepChr;
        const lastIdx = this._lastIdx;
        const done = this._done;
        this._peekRet = this.next();
        // Restore state
        this._stringAcc = stringAcc;
        this._lastSepChr = lastSepChr;
        this._lastIdx = lastIdx;
        this._done = done;
        return this._peekRet;
    }
    /**
     * Seeks to the specified place based on matching a part, returns what was
     * seeked over
     * @param toMatch Object with keys mapped to strings to match to the return
     * of the iterator
     * @param exclusive Set this to seek just UP TO the match but not actually
     * seekit itself.
     */
    seekTo(toMatch, exclusive = false) {
        if (this._done) {
            return '';
        }
        let acc = '';
        const partMatch = (part) => Object.entries(toMatch).every(([k, v]) => part[k] === v);
        if (exclusive) {
            let part = this.peek();
            while (!part.done && !partMatch(part.value)) {
                acc += part.value.string;
                this.next(); // Skip the peeked part
                part = this.peek();
            }
        }
        else {
            let part;
            while (!part || !part.done && !partMatch(part.value)) {
                part = this.next();
                if (part.done) {
                    break; // Nothing to add
                }
                acc += part.value.string;
            }
        }
        return acc;
    }
    /**
     * Returns the rest of the iterator, without consuming any of it
     * @return {string} rest of the path
     */
    rest() {
        if (this._done) {
            return '';
        }
        const restPath = this._pathStr.slice(this._lastIdx);
        // If the last seperator was a ',' then we were half way through params when doing
        // rest, so include a ',' so that the next consumer of the path knows name
        // is empty
        return `${this._lastSepChr === ',' ? ',' : ''}${restPath}`;
    }
    [Symbol.iterator]() {
        return this;
    }
}
/**Path utilities in MapCast that operate at the "part" level. Some interesting
 * things to note:
 * * Both '/' and ',' are reserved characters for part separators. You will get
 * unexpected results if your names contain either of these and then you try to
 * split a path with them in it
 * * A trailing '/' is not considered a "normal" path. We want to avoid quirks where
 * you can use a path ending and not ending in '/' to refer to two different things.
 * Users may still construct paths ending in '/' but some APIs expect you to strip
 * off the ending '/' and pass a "normal" path
 * @todo is ',' the other separator?
 */
export class PathPart {
    /**Taking a path, normalizes:
     * * Remove double separators
     * * Removes the any trailing '/' or ','
     * @todo In cases of ',/' or '/,', how do we handle this (currently it ignores)
     */
    static normalize(path) {
        let ret = '';
        for (let i = 0; i < path.length; i++) {
            const chr = path[i];
            const lastChr = ret[ret.length - 1];
            if (!lastChr
                || (chr !== '/' && chr !== ',')) {
                ret += chr;
                continue;
            }
            if (lastChr === chr) {
                // '//' or ',/' or '/,' or ',,' reduce to the first character
                // TODO: This is ambiguous with ',/' and '/,' so maybe we should
                // throw in those cases?
                continue;
            }
            ret += chr;
        }
        const lastIndex = ret.length - ret.split('').reverse().findIndex(c => c !== '/' && c !== ',');
        return ret.slice(0, lastIndex);
    }
    // Typescript for some reason needs the & Iterable... because it wont pick up
    // the inner type from PathPartIterator otherwise and returns any
    static itr(path) {
        return new PathPartIterator(path);
    }
    /**Joins the arguments passed as path parts using '/'*/
    static joinStrs(...args) {
        return this.joinArray(args);
    }
    /**Joins the passed iterable as path parts using '/'. Skips empty parts, removes
     * any leading separators from parts, and will ensure no trailing separators.*/
    static joinArray(pathStrs) {
        let joined = '';
        for (const pStr of pathStrs) {
            if (pStr === undefined || pStr === null || pStr === '') {
                continue;
            }
            else if (typeof pStr === 'string') {
                // Find the string without any leading or trailing separators
                let firstIndex, lastIndex;
                for (let i = 0; i < pStr.length; i++) {
                    const c = pStr[i];
                    if (c === '/' || c === ',') {
                        continue;
                    }
                    if (firstIndex === undefined) {
                        firstIndex = i;
                    }
                    lastIndex = i;
                }
                if (firstIndex === undefined || lastIndex === undefined) {
                    // It was all separators
                    if (!joined) {
                        // Set joined to '/' if all separators (leading '/')
                        joined = '/';
                    }
                    continue;
                }
                const pStrSlice = pStr.slice(firstIndex, lastIndex + 1);
                if (joined === '/'
                    || (joined === '' && firstIndex === 0)) {
                    // No accumulation yet, add without the separator. Also makes sure in
                    // the second case that there was no leading separator
                    joined += pStrSlice;
                }
                else {
                    joined += `/${pStrSlice}`;
                }
            }
        }
        // leading and trailing separators are handled by the loop logic above
        return joined;
    }
    static join(pathParts) {
        return Array.from(pathParts)
            .map(part => part.string)
            .join('/');
    }
    /**Traverses object o's properties using the path pathStr*/
    // static traverse(o: any, path: string) {
    //   const itr = Path.split(path);
    //   let obj = o;
    //   for (const chunk of itr) {
    //     // Handle part.name traversal
    //     if (chunk.name !== '') {
    //       obj = obj[chunk.name] as any; // Traverse the property
    //     }
    //   }
    //   return obj;
    // }
    static slice(path, start = 0, endExclusive = 0) {
        const pathParts = Array.from(this.itr(path));
        return this.join(pathParts.slice(start, endExclusive));
    }
    static head(path) {
        const pathParts = Array.from(this.itr(path));
        return [
            pathParts[0]?.value ?? '',
            this.join(pathParts.slice(1))
        ];
    }
    static tail(path) {
        const pathParts = Array.from(this.itr(path));
        return [
            pathParts[pathParts.length - 1]?.value ?? '',
            this.join(pathParts.slice(0, -1))
        ];
    }
    static parent(path) {
        if (path === '/') {
            return '';
        }
        const [, rest] = this.tail(path);
        if (path.startsWith('/') && rest === '') {
            // One level up from one above root is root, but only if the
            // passed path was rooted with a preceeding /
            return '/';
        }
        return rest;
    }
}
/**Utilities that work on paths*/
export const Path = PathPart;
