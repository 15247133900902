import { strict as assert } from 'assert';
import { FileContainingPngImage } from './Image.js';
/**
 * Resolves full file paths mapping them onto the disk filepaths
 * @todo fsImpl typing for fs interface
 */
export class FileExtMiddleware {
    constructor(extMiddlewareMap) {
        assert(typeof extMiddlewareMap === 'object', `extMiddlewareMap must be of type 'object'; was '${typeof extMiddlewareMap}'`);
        this.__extMiddlewareMap = extMiddlewareMap;
    }
    async get(prev) {
        const file = await prev;
        const extMw = this.__extMiddlewareMap[file.ext];
        if (!extMw || !extMw.get) {
            return prev;
        }
        return extMw.get(file);
    }
}
export class ImageFileMiddleware {
    async get(prev) {
        const file = await prev;
        return FileContainingPngImage.fromFile(file);
    }
}
