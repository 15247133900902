import React, { useState, useEffect } from 'react';
function debugStringify(o, replacer, indentNum) {
    if (typeof o !== 'object' || o === null) {
        return JSON.stringify(o, replacer, indentNum);
    }
    const indent = ' '.repeat(indentNum);
    const kvSrc = Object.entries(o)
        .map(([k, v]) => {
        let vSrc = replacer(k, v);
        if (vSrc === undefined) {
            return undefined;
        }
        if (typeof vSrc !== 'string' || vSrc === v) {
            vSrc = debugStringify(v, replacer, indentNum);
        }
        vSrc = indent + vSrc.replace(/\n/g, '\n  '); // Add indentation to every line
        return `${indent}"${k}": ${vSrc}`;
    })
        .filter(s => s !== undefined)
        .join(',\n');
    return `{\n${kvSrc}\n}`;
}
/**
 * Converts any type to a string, with special provisions for Dataset
 * @param ds A dataset object to convert
 * @param cb An optional callback function that will be called everytime
 * an asynchronous property updates
 * @todo This doesnt need to handle everything anymore, it could just handle dataset,
 * due to how we do type matching, but it might be nice to have one big one at some
 * point that does _all_ the fancy stuff
 */
function datasetToString(ds, cb) {
    // Replacer to hide '__' prefixed values
    const replacer = (k, v) => {
        if (k.startsWith('__')) {
            return undefined;
        }
        if (typeof v === 'function') {
            return `function ${v.name}(){}`;
        }
        return v;
    };
    if (ds?.toSync) {
        const syncObj = ds.toSync(cb, true);
        return JSON.stringify(syncObj, replacer, 2);
    }
    else if (typeof ds === 'string') {
        // Return as-is so it pretty prints with newlines and such
        return ds;
    }
    else if (typeof ds === 'function') {
        // Can't be stringified with JSON.stringify
        return `function ${ds.name}(){}`;
    }
    return debugStringify(ds, replacer, 2);
}
export default function MultiDebugPrinter(props) {
    const { data: ds } = props;
    const [dsText, setDSText] = useState('');
    const [promisesLeft, setPromisesLeft] = useState(0);
    useEffect(() => {
        const dsToStr = () => {
            const str = datasetToString(ds, async (promise) => {
                setPromisesLeft(prev => prev + 1);
                setDSText(dsToStr());
                promise
                    .catch(() => { }) // Handle rejections
                    .finally(() => {
                    if (reqDs !== ds) {
                        return; // ds changed, do not update
                    }
                    setPromisesLeft(prev => prev - 1);
                    setDSText(dsToStr());
                });
            });
            const showPromises = !!ds?.toSync;
            const showPromisesPrefix = showPromises
                ? `Promises ${promisesLeft}\n`
                : '';
            return `${showPromisesPrefix}${str}`;
        };
        const reqDs = ds;
        setDSText(dsToStr());
    }, [ds]);
    return (React.createElement("pre", null, dsText));
}
