import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
export function usePrevious(value, initialValue) {
    const ref = useRef(initialValue ?? null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
export function useConstructor(callBack = () => { }) {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled)
        return;
    callBack();
    setHasBeenCalled(true);
}
function depsChanged(deps1, deps2) {
    if (deps1.length !== deps2.length) {
        throw new Error('Different amount of dependencies');
    }
    return deps1.some((d1, idx) => deps2[idx] !== d1);
}
;
/**Executes the callback fn synchronously immediately (when you call useEffectImmediate)
 * and also synchronously whenever the deps change. Can also return a cleanup
 * function similar to useEffect
 */
export function useEffectImmediate(fn, deps) {
    const oldDeps = usePrevious(deps);
    const callbackFn = useCallback(fn, deps);
    const cleanupFn = useRef();
    if (!oldDeps || depsChanged(deps, oldDeps)) {
        cleanupFn.current = callbackFn();
    }
    useEffect(() => {
        return cleanupFn.current;
    }, deps);
}
export function useKey(key, preventDefault = true) {
    const [holdingKey, setHoldingKey] = useState(false);
    useEffect(() => {
        const keyFunc = (e) => {
            if (e.key !== key) {
                return;
            }
            if (preventDefault) {
                e.preventDefault();
            }
            setHoldingKey(e.type === 'keydown');
        };
        window.addEventListener('keydown', keyFunc);
        window.addEventListener('keyup', keyFunc);
        return () => {
            window.removeEventListener('keydown', keyFunc);
            window.removeEventListener('keyup', keyFunc);
        };
    }, [key]);
    return holdingKey;
}
const compareInputs = (inputKeys, oldDepObj, newDepObj) => {
    inputKeys.forEach(key => {
        const oldInput = oldDepObj[key];
        const newInput = newDepObj[key];
        if (oldInput !== newInput) {
            console.log("change detected", key, "old:", oldInput, "new:", newInput);
            //console.trace();
        }
    });
};
/**Compare keys in depObj with each other to see who changed, useful for debugging why components
 * are rerendering or not rerendering*/
export function useDebugDeps(depObj) {
    const oldDepObjRef = useRef(depObj);
    useMemo(() => {
        compareInputs(Object.keys(depObj), oldDepObjRef.current, depObj);
        oldDepObjRef.current = depObj;
    }, Object.values(depObj)); // eslint-disable-line react-hooks/exhaustive-deps
}
;
