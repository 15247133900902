/** Base class for InspectableIterator and InspectableAsyncIterator */
export class InspectableIteratorBase {
    /**
     * @param refPrevValues An array of previous values for the passed wrappedItr. It is
     * internally stored _by reference_, so if you update it from somewhere else, it will
     * use these previous values before calling wrapperItr.next(). Useful to only every
     * query a backing iterator .next() one time per item for many potential ref
     * iterators, regardless of the order that any of them iterate.
     */
    constructor(wrappedItr, refPrevValues = []) {
        this._prevValues = refPrevValues;
        this._prevValuesIdx = 0;
        this._itr = wrappedItr;
        this.$inspect = {
            done: false,
            values: [],
            thrown: false,
            thrownWith: undefined,
            returnedWith: null,
            onValue: null,
            onChange: null
        };
    }
    /**
     * Memoizes a generator function. When called, will create a new generator to
     * iterate over all previous values pulled before pulling new values.
     * (loosely typed, implementor to implement more strict return typing)
     *
     * WARNING! .iterator on $memo is the _source of truth iterator_. When any iterator that
     * references it is updated, it might suddenly call .next() and consume an item to
     * provide to the child ref iterator. Call the memoized generator function again or use
     * ._newRefIterator() if you want a new iterator that uses .iterator as the backing
     * store.
     */
    static _memoizeGenFunc(genFunc) {
        // No good ways to specify that our implementors will have specific static properties, so we do a cast
        // to something that's typed like what it should be
        const ThisCls = this;
        const iiFunc = ThisCls.wrapGenFunc(genFunc);
        const $memo = {
            iterator: null,
            clear() {
                $memo.iterator = null;
            }
        };
        function memoizedWrappedGenFunc() {
            if ($memo.iterator) {
                // There was a previous iterator, "reset" it
                const prevItr = $memo.iterator;
                return prevItr._newRefIterator();
            }
            const ii = iiFunc();
            $memo.iterator = ii;
            // Return a new reference iterator so that we don't give out the source of
            // truth iterator (and end up with unexpected .next() yields when the first
            // iterator is iterated after the second one). Always use a referencing iterator
            return ii._newRefIterator();
        }
        ;
        memoizedWrappedGenFunc.$memo = $memo;
        return memoizedWrappedGenFunc;
    }
    /**Creates a new InspectableIterator that references the current iterator's
     * seen values. If next() is called on this iterator, the reference iterator will
     * be notified via the previous values array reference and won't call next() until
     * it has exhausted those previous values.
     */
    _newRefIterator() {
        const ThisCls = Object.getPrototypeOf(this).constructor;
        return new ThisCls(this, this.$inspect.values);
    }
    /**Creates a new InspectableIterator that iterates over this iterators $inspect.values
     * and continues to call next() if it has exhausted those values */
    reIterate() {
        return this._newRefIterator();
    }
    /** If there's more previous values to iterate over still */
    _hasMorePrevValues() {
        return this._prevValuesIdx < this._prevValues.length;
    }
    _takePrevValueOrUndefined() {
        if (this._hasMorePrevValues()) {
            const ret = this._prevValues[this._prevValuesIdx];
            const _yield = {
                value: ret,
                done: false
            };
            this._pushInspectValue(_yield);
            return _yield;
        }
        return undefined;
    }
    /** Push an inspect value onto the inspect stack */
    _pushInspectValue(_next) {
        if (_next instanceof Promise) {
            console.warn("InspectableIteratorBase#_pushInspectValue received Promise from .next(). Did you wrap an async iterator in a normal iterator?");
        }
        this.$inspect.done = !!_next.done;
        if (!_next.done) {
            this.$inspect.values.push(_next.value);
            // _itr.next() will have caused any underlying InspectableIterator to update
            // it's values, so we need to adjust out _prevValuesIdx to account for it,
            // It's safe even if it doesn't cause it'll be >
            this._prevValuesIdx += 1;
        }
    }
}
/**
 * Wraps an iterator to be able to inspect it's state
 * without calling it. This includes all previous values and the doneness
 */
export class InspectableIterator extends InspectableIteratorBase {
    static is(itr) {
        return !!itr.$inspect;
    }
    static wrapIterator(itr) {
        if (this.is(itr)) {
            return itr; // Already wrapped
        }
        return new this(itr);
    }
    /** Wraps a generator function to return a InspectableIterator instead of a Generator */
    static wrapGenFunc(genFunc) {
        const self = this;
        return function (...args) {
            return self.wrapIterator(genFunc.apply(this, args));
        };
    }
    /**
     * Memoizes a generator function. When called, will create a new generator to
     * iterate over all previous values pulled before pulling new values.
     */
    static memoizeGenFunc(genFunc) {
        return super._memoizeGenFunc(genFunc);
    }
    constructor(iterator, refPrevValues = []) {
        super(iterator, refPrevValues);
    }
    next() {
        const _yield = this._takePrevValueOrUndefined();
        if (_yield) {
            return _yield;
        }
        // Start pulling new values from _itr
        const next = this._itr.next();
        this._pushInspectValue(next);
        if (typeof this.$inspect.onValue === 'function') {
            this.$inspect.onValue();
        }
        if (typeof this.$inspect.onChange === 'function') {
            this.$inspect.onChange();
        }
        return next;
    }
    /** Takes n items from the iterator */
    take(n) {
        // Take a set amount of items or until the iterator is done. Use a plain for
        // loop because otherwise a for await will close the iterator and stop any
        // further iteration
        for (let i = 0; i < n; i++) {
            const ret = this.next();
            if (ret.done) {
                break;
            }
        }
    }
    return(value) {
        // _itr may or may not have a .return(), let it fail if it does
        return this._itr.return(value);
    }
    throw(e) {
        // _itr may or may not have a .throw(), let it fail if it does
        return this._itr.throw(e);
    }
    [Symbol.iterator]() {
        return this;
    }
}
/** Wraps AsyncIterator and AsyncGenerator to provide a synchronous $inspect property */
export class InspectableAsyncIterator extends InspectableIteratorBase {
    static is(itr) {
        return !!itr.$inspect;
    }
    static wrapIterator(itr) {
        if (this.is(itr)) {
            return itr; // Already wrapped
        }
        return new this(itr);
    }
    /** Wraps an async generator function to return a InspectableAsyncIterator instead of an AsyncGenerator */
    static wrapGenFunc(genFunc) {
        const self = this;
        return function (...args) {
            return self.wrapIterator(genFunc.apply(this, args));
        };
    }
    /**
     * Memoizes a async generator function. When called, will create a new async generator to
     * iterate over all previous values pulled before pulling new values.
     */
    static memoizeGenFunc(genFunc) {
        return super._memoizeGenFunc(genFunc);
    }
    constructor(iterator, refPrevValues = []) {
        super(iterator, refPrevValues);
    }
    next() {
        const _yield = this._takePrevValueOrUndefined();
        if (_yield) {
            return Promise.resolve(_yield);
        }
        // Wrap next in a Promise that stores the value in the $inspect
        return this._itr.next()
            .then((ret) => {
            this._pushInspectValue(ret);
            if (typeof this.$inspect.onValue === 'function') {
                this.$inspect.onValue();
            }
            if (typeof this.$inspect.onChange === 'function') {
                this.$inspect.onChange();
            }
            return ret;
        });
    }
    /** Takes n items from the iterator */
    async take(n) {
        // Take a set amount of items or until the iterator is done. Use a plain for
        // loop because otherwise a for await will close the iterator and stop any
        // further iteration
        for (let i = 0; i < n; i++) {
            const ret = await this.next();
            if (ret.done) {
                break;
            }
        }
    }
    // return() and throw() to forward on these to the _itr for generators
    return(value) {
        // _itr may or may not have a .return(), let it fail if it does
        return this._itr.return(value);
    }
    throw(e) {
        // _itr may or may not have a .throw(), let it fail if it does
        return this._itr.throw(e);
    }
    [Symbol.asyncIterator]() {
        return this;
    }
}
InspectableAsyncIterator.wrapGenFunc(async function* asdf() { yield 1; });
