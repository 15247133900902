import React, { useMemo, useState, useEffect } from 'react';
import scss from './Collapseable.module.scss';
export default function Collapseable(props) {
    const { children: _children, initialOpen, canOpen, onChange } = props;
    const children = useMemo(() => {
        return Array.isArray(_children)
            ? _children
            : [_children];
    }, [_children]);
    const [open, setOpen] = useState(initialOpen ?? false);
    function toggleCollapse() {
        setOpen(prev => !prev);
    }
    useEffect(() => {
        if (!onChange) {
            return;
        }
        onChange(open);
    }, [open]);
    return (React.createElement("span", { className: `${scss.collapseable} ${open ? '' : 'collapsed'}` },
        canOpen && React.createElement("button", { onClick: toggleCollapse }, open ? 'v' : '>'),
        React.createElement("span", { className: 'collapsed-first-child' }, children[0]),
        React.createElement("div", { className: 'children' }, children.slice(1))));
}
